import { observable, computed } from 'mobx';
import { Casts, Model, Store } from 'store/Base';
import { Contract } from './Contract';
import { SortedActivityStore, TYPE_TANKING, TYPE_CHANGE, TYPE_TRUCK_SWAP, STATUS_FINISHED, STATUS_ETA, STATUS_WAITING, STATUS_ARRIVED, STATUS_STARTED, STATUS_PLANNED } from './Activity';
import { Assignment, AssignmentStore } from './Assignment';
import { UserStore } from './User';
import { AllocationEquipmentStore } from './AllocationEquipment';
import { Trailer } from './Trailer';
import { omit, some } from 'lodash';
import { debounce } from 'lodash';
import moment from 'moment';
import { ReassignmentChangeStore } from './ReassignmentChange'
import { showNotification } from '@code-yellow/spider';

function comparatorIsFetched(a, b) {
    if (a._isFetched && !b._isFetched) {
        return -1;
    }

    if (!a._isFetched && b._isFetched) {
        return 1;
    }
}


/**
 * Only 1 driver change possible. Driver changes are either unplanned
 * assignments, or already planned assignment.
 */
export class Allocation extends Model {
    static backendResourceName = 'allocation';

    @observable id = null;
    @observable customerCode = '';
    @observable truckType = 'standard';
    @observable remarks = '';
    @observable unfinalizedActivityCount = 0; // not a property of allocation itself
    @observable needsAttention = '2-neutral';
    @observable lastActivityFinishedDatetime = null;
    @observable lastNewPlanRequestSentDatetime = null;

    // Only set when ?include_target_driven_kms=true
    @observable kmWeekActual = 0;
    @observable kmDay = 0;
    @observable kmPlannedUntilTomorrow = 0;
    @observable canBePlanned = false;

    // Only set when ?include_km_deviation_data
    @observable activitiesIds = [];
    @observable totalPlannedDeviation = 0;
    @observable totalDriverDeviation = 0;
    @observable totalPlannedDeviationPercentage = 0;
    @observable totalDriverDeviationPercentage = 0;
    @observable estimatedCostOfPlannedDeviation = 0;
    @observable estimatedCostOfDriverDeviation = 0;

    @observable startDate = null;
    @observable endDate = null;

    @observable channelTeamsId = null;

    // attr used to have filterable store by 'problems' of an line
    // it is used in AssetPlanning, so user can select lines with an error
    @observable _hasProblems = {};
    @observable _isOpen = false;

    @observable _isFetched = false;

    relations() {
        return {
            contract: Contract,
            activities: SortedActivityStore,
            users: UserStore,
            currentTrailer: Trailer,

            assignments: AssignmentStore,
            currentAssignment: Assignment,

            equipments: AllocationEquipmentStore,
            reassignmentsChange: ReassignmentChangeStore,
        };
    }
    casts() {
        return {
            lastActivityFinishedDatetime: Casts.datetime,
            lastNewPlanRequestSentDatetime: Casts.datetime,
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }

    toBackend(options) {
        const data = super.toBackend(options);
        return omit(data, ['unfinalized_activity_count', 'can_be_planned']);
    }

    @computed get hasTankingActivity() {
        return some(this.activities.models, activity => activity.type === TYPE_TANKING);
    }

    @computed
    get activityIssues() {
        let activityIssues = [];

        if (!this.activities) {
            return activityIssues;
        }

        this.activities.forEach(activity => {
            activityIssues = activityIssues.concat(activity.issues.models.map(a => a));
        })

        return activityIssues;
    }

    /**
     * The active assignment if firstly the assignment of the current activity.
     * If it doesn't exist, use currentAssignment.
     */
    @computed
    get activeAssignment() {
        return this.currentActivity ? this.currentActivity.assignment : this.currentAssignment;
    }

    maybeSendFirstActivity(activity) {
        return new Promise(resolve => {
//             import('helpers/activity').then(({ sendRouteWithFallback }) => {
//                 // {copy-pasta-send-first-activty}
//                 // Important: should happen before the allocation.fetch(),
//                 // because in that case this code never fires (this activity will be current)
//                 if (
//                     this.currentAssignment.id &&
//                     activity.status === 'eta' &&
//                     activity.isAsap
//                 ) {
//                     return sendRouteWithFallback(activity).catch(() => {}).then(resolve);
//                 }
//
//                 resolve();
//             });
        });
    }

    hasCustomerUpdateWarning() {
        try {
            // We're not looking at the Full Customer Update (excel) here, but we should look at the last activity messages
            const now = moment();
            let lastStatusUpdateSentAt = this.currentActivity.lastStatusUpdateSentAt;

            // Check status update delay reason time.
            if (this.currentActivity.lastStatusUpdateDelayReasonTime) {
                if (this.currentActivity.lastStatusUpdateDelayReasonTime < now) {
                    return true;
                }
            }

            if (!lastStatusUpdateSentAt && [STATUS_WAITING, STATUS_ARRIVED, STATUS_ETA].includes(this.currentActivity.status)) {
                const attr = this.currentActivity.getStatusDatetimeAttr(this.currentActivity.status);

                if (attr) {
                    lastStatusUpdateSentAt = this.currentActivity[attr];
                }
            }

            if (lastStatusUpdateSentAt) {
                const updateInterval = this.contract.customer.updateInterval ? this.contract.customer.updateInterval : 0

                if (updateInterval !== 0 && lastStatusUpdateSentAt !== null) {
                    // {copy-pasta-customer-update-deadline}
                    const customerUpdateDeadline = lastStatusUpdateSentAt.clone().add(updateInterval, 'm');
                    const { amount, startCountingDatetime } = this.calcWaitingRateAmount(this.currentActivity, true);

                    if ([STATUS_ARRIVED, STATUS_WAITING, STATUS_STARTED].includes(this.currentActivity.status)) {
                        if (customerUpdateDeadline < now) {
                            return true;
                        }

                        if (amount && startCountingDatetime > lastStatusUpdateSentAt) {
                            return true;
                        }
                    }
                }
            }

            return false;
        } catch (e) {
            return false;
        }
    }

    calcWaitingRateAmount(activity, includingMinutes = false) {
        if (this.contract && this.contract.tariffs && this.contract.tariffs.length > 0 && activity) {
            // Takes the tariff with highest start date, might not be 100%
            // correct.
            const tariff = this.contract.tariffs.at(0);
            activity._finishedDatetime = moment();

            return tariff.calcWaitingRateAmount(activity, includingMinutes);
        }

        return 0;
    }

    calcWaitingRateStart(activity) {
        if (this.contract && this.contract.tariffs && this.contract.tariffs.length > 0 && activity) {
            // Takes the tariff with highest start date, might not be 100%
            // correct.
            const tariff = this.contract.tariffs.at(0);
            activity._finishedDatetime = moment();

            return tariff.calcWaitingRateStart(activity);
        }

        return 0;
    }

    calcWaitingRateMinutes(activity) {
        if (this.contract && this.contract.tariffs && this.contract.tariffs.length > 0 && activity) {
            // Takes the tariff with highest start date, might not be 100%
            // correct.
            const tariff = this.contract.tariffs.at(0);
            activity._finishedDatetime = moment();

            return tariff.calcWaitingRateMinutes(activity);
        }

        return 0;
    }

    /**
     * Find assigments which are driver/truck changes and not yet planned.
     *
     * @return {Array}
     */
    unplannedReassignmentChange(reassignmentChangeStore) {
        if (!reassignmentChangeStore) {
            return [];
        }

        const result = [];
        const plannedReassignmentChangeIds = this.activities.filter({ type: TYPE_CHANGE }).map(a => a.reassignmentChange.id);

        reassignmentChangeStore.models.forEach(r => {
            if (r.allocation.id === this.id && !plannedReassignmentChangeIds.includes(r.id)) {
                result.push(r);
            }
        });

        return result;
    }

    unplannedReassignmentTruckswap(reassignmentTruckswapStore) {
        if (!reassignmentTruckswapStore) {
            return [];
        }

        const result = [];
        const plannedReassignmentTruckswapIds = this.activities.filter({ type: TYPE_TRUCK_SWAP }).map(a => a.reassignmentTruckswap.id);

        reassignmentTruckswapStore.models.forEach(r => {
            if (r.assignment1.id === this.currentAssignment.id && !plannedReassignmentTruckswapIds.includes(r.id)) {
                result.push(r);
            }

            if (r.assignment2.id === this.currentAssignment.id && !plannedReassignmentTruckswapIds.includes(r.id)) {
                result.push(r);
            }
        });

        return result;
    }

    /**
     * Find all reassignment truckswap for this allocation, either planned or unplanned.
     */
    upcomingReassignmentTruckswap(reassignmentTruckswapStore) {
        const result = [];

        reassignmentTruckswapStore.models.forEach(r => {
            if (
                r.assignment1.id === this.currentAssignment.id ||
                r.assignment2.id === this.currentAssignment.id
            ) {
                result.push(r);
            }
        });

        return result;
    }

    /**
     * Find other reassignment activities which come before reassignmentActivity.
     */
    getInterposingReassignments(reassignmentActivity) {
        if (!reassignmentActivity || !this.activities) {
            return [];
        }

        return this.activities.filter(ac =>
            ac.status !== STATUS_FINISHED &&
            [TYPE_CHANGE, TYPE_TRUCK_SWAP].includes(ac.type) &&
            (reassignmentActivity ? reassignmentActivity.id !== ac.id && ac.orderedArrivalDatetimeFrom < reassignmentActivity.orderedArrivalDatetimeFrom : true)
        );
    }

    /**
     * Find all current + future assignments which are not deleted.
     */
    @computed
    get upcomingAssignments() {
        return this.assignments.filter(a => a.endDatetime === null && !a.deleted && a.id !== this.currentAssignment.id);
    }

    updateEta() {
        const previousActivities = [];
        const firstActivityNotPlanned = this.currentActivity ? this.currentActivity.status !== STATUS_PLANNED : false;

        this.activities.forEach(activity => {
            // Only calculate eta info when first activity is driving. Otherwise
            // the eta is calculated but the truck is not even driving.
            if (firstActivityNotPlanned) {
                const info = activity.calculateETAInfo(previousActivities, this.currentAssignment);
                activity.estimatedArrivalDatetime = info.ETA;
                activity._etaInfo = info;
                previousActivities.push(activity);
            } else {
                activity._etaInfo = {};
                activity.estimatedArrivalDatetime = null;
            }
        });
    }

    /**
     * An activity assignment might not yet be known, so try to calculate what
     * the activity assignment might be.
     */
    calcActivityAssignment(activity) {
        const calculatedAssignment = new Assignment(null, { relations: ['truck.entity', 'driver1', 'driver2'] });

        if (activity.assignment && !activity.assignment.isNew) {
            // Specific hack for T17124, see below for more explanation.
            if (activity.assignment.id === this.currentAssignment.id) {
                return this.currentAssignment;
            }

            return activity.assignment;
        }

        calculatedAssignment.parse(this.currentAssignment.toJS());

        let seenActivity = false;

        this.activities.forEach((a, i) => {
            if (a.id === activity.id) {
                seenActivity = true;
            }

            if ((!seenActivity || i === 0) && [TYPE_CHANGE, TYPE_TRUCK_SWAP].includes(a.type)) {
                if (a.type === TYPE_TRUCK_SWAP) {
                    calculatedAssignment.id = null;

                    if (calculatedAssignment.truck.id === a.reassignmentTruckswap.assignment1.truck.id) {
                        calculatedAssignment.truck.parse(a.reassignmentTruckswap.assignment2.truck.toJS());
                        calculatedAssignment.driver1.parse(a.reassignmentTruckswap.assignment2.driver1.toJS());
                        calculatedAssignment.driver2.parse(a.reassignmentTruckswap.assignment2.driver2.toJS());
                    } else {
                        calculatedAssignment.truck.parse(a.reassignmentTruckswap.assignment1.truck.toJS());
                        calculatedAssignment.driver1.parse(a.reassignmentTruckswap.assignment1.driver1.toJS());
                        calculatedAssignment.driver2.parse(a.reassignmentTruckswap.assignment1.driver2.toJS());
                    }
                } else if (a.type === TYPE_CHANGE) {
                    calculatedAssignment.id = null;

                    calculatedAssignment.truck.parse(a.reassignmentChange.newTruck.toJS());
                    calculatedAssignment.driver1.parse(a.reassignmentChange.newDriver1.toJS());
                    calculatedAssignment.driver2.parse(a.reassignmentChange.newDriver2.toJS());

                    // Specific property added only for action footer
                    if (this.currentAssignment.driver1) {
                        calculatedAssignment.driverChanged = this.currentAssignment.driver1.id !== a.reassignmentChange.newDriver1.id;
                    } else if (a.reassignmentChange.newDriver1.id) {
                        calculatedAssignment.driverChanged = true;
                    }
                }
            }
        });

        // Specific hack for T17124.
        // We save a conceptMessage on a truck, and each assignment model
        // creates a truck model. This hack avoids having to refetch the truck
        // on showing the chatbox.
        if (this.currentAssignment.truck.id === calculatedAssignment.truck.id) {
            calculatedAssignment.truck = this.currentAssignment.truck;
        }

        return calculatedAssignment;
    }

    isAssignmentPlanned(assignment) {
        // XXX TODO: This is no longer a thing?
        return false;

        // if (!assignment) {
        //     return false;
        // }

        // return some(this.activities.models, a => !a.deleted && a.nextAssignment.id === assignment.id);
    }

    @computed
    get currentActivity() {
        if (this.activities.length > 0) {
            return this.activities.at(0);
        }

        return null;
    }

    @computed
    get currentPlanner() {
        if (this.users.length > 0) {
            return this.users.at(0);
        }

        return null;
    }

    @computed
    get nextActivity() {
        if (this.activities.length > 1) {
            return this.activities.at(1);
        }

        return null;
    }

    @computed
    get nextNextActivity() {
        const MINIMUM_NUMBER_OF_ALLOCATIONS_TO_SHOW_ANOTHER_TILE = 2

        if (this.activities.length > MINIMUM_NUMBER_OF_ALLOCATIONS_TO_SHOW_ANOTHER_TILE) {
            return this.activities.at(MINIMUM_NUMBER_OF_ALLOCATIONS_TO_SHOW_ANOTHER_TILE);
        }
        return null;
    }

    @computed
    get otherActivities() {
        return this.activities.filter(
            a => a !== this.currentActivity && a !== this.nextActivity
        );
    }

    setUnfinishedActivitiesParams() {
        this.setFetchParams({
            where: 'activities(status:not=finished),activities(deleted=false)',
        });
    }

    /**
     * {copy-paste-findNextActivity}
     */
    findNextActivity(activity) {
        const i = this.activities.map(a => a.id).indexOf(activity.id);

        try {
            const next = this.activities.at(i + 1);

            if (next.deleted) {
                return this.findNextActivity(next);
            }

            return next;
        } catch (e) {
            return null;
        }
    }

    findNextNoStopActivity(activity) {
        const i = this.activities.map(a => a.id).indexOf(activity.id);

        try {
            const next = this.activities.at(i + 1);

            if (next.deleted || next.kind === 'boek stop') {
                return this.findNextNoStopActivity(next);
            }

            return next;
        } catch (e) {
            return null;
        }
    }

    /**
     * Finds previous activity. Will skip deleted activities.
     *
     * Different from backend, since the activity.previousActivity also skips
     * stops.
     *
     * {copy-paste-findPreviousActivity}
     */
    findPreviousActivity(activity) {
        const i = this.activities.map(a => a.id).indexOf(activity.id);

        try {
            const prevIndex = i - 1;

            if (prevIndex < 0) {
                return null;
            }

            const prev = this.activities.at(prevIndex);

            if (prev.deleted) {
                return this.findPreviousActivity(prev);
            }

            return prev;
        } catch (e) {
            return null;
        }
    }

    // ++++ Websocket special care ++++
    // Take a look at T17175 for a bit more context. Basically during the save
    // of an activity, a websocket forces the allocation to refresh, which
    // might break stuff (like suddenly the activity is filtered out, so it
    // doesn't exist in the allocation anymore).
    @observable _canRefresh = true;
    @observable _shouldRefresh = false;

    refreshWhenPossible = debounce(({ afterFetch } = {}) => {
        if (this._canRefresh && this.id) {
            // See https://phabricator.codeyellow.nl/T17150#373436 for why
            // supressError needs to be true. Sometimes filter are wrongely
            // applied.
            this.fetch({ supressError: true }).catch(() => {})
                .then(() => this._shouldRefresh = false)
                .then(() => afterFetch && afterFetch());
        } else {
            this._shouldRefresh = true;
        }
    }, 1000);

    disableRefresh() {
        this._canRefresh = false;
    }

    enableRefresh() {
        // T16146 I don't know why but sometimes it tries to fetch allocation without id!!
        if (this._shouldRefresh && this.id) {
            return this.fetch()?.catch(() => {}).then(() => this._canRefresh = true);
        } else {
            this._canRefresh = true;
        }
    }
    // ---- Websocket special care ----

    addMemberToChannel(userJson) {
        return this.api.post(this.url + 'add_member_to_channel/', { 'user': userJson })
        .catch(error => {
            const errors = error.response.data.errors;
            console.log(errors);
        });
    }

    removeMemberFromChannel(users) {
        return this.api.post(this.url + 'remove_member_from_channel/', { 'users': users })
        .catch(error => {
            const errors = error.response.data.errors;
            console.log(errors);
        });
    }

    refreshTeamsChannel() {
        return this.api.post(this.url + 'refresh_teams_channel/').then( res => {
            if (res['response'] === 'ok') {
                showNotification({ type: 'info', message: t('Channel is refreshing!') })
            }
        })
    }


    getCountriesOnRouteForParking() {
        return this.api.get(`${this.url}get_countries_on_route_for_parkings/`, {})
    }

    shouldDisplayChat() {
        return this.contract.activateTeams && this.contract.teamsId && this.channelTeamsId
    }
}

export class AllocationStore extends Store {
    Model = Allocation;
    static backendResourceName = 'allocation';

    // {save-users-copy-paste}
    saveUsers() {
        const modelOptions = { fields: ['id', 'users'] };
        const data = this.map(m => m.toBackend(modelOptions));
        return this.api.put(this.url(), {
            data,
        });
    }

    saveNeedsAttention() {
        const modelOptions = { fields: ['needsAttention'] };
        const data = this.map(m => m.toBackend(modelOptions));
        return this.api.put(this.url(), {
            data,
        });
    }

    getContracts() {
        // returns unique contracts in allocation store
        return [...new Set(this.map(a => a.contract.id).filter(id=>id))];
    }

    getContractsParams() {
        // returns unique contracts from store in params string format
        return this.getContracts().join(',');
    }


    comparatorDefault(a, b) {
        const sortIsFetched = comparatorIsFetched(a, b);

        if (sortIsFetched !== undefined) {
            return sortIsFetched;
        }

        if (a._isFetched && !b._isFetched) {
            return -1;
        }

        if (!a._isFetched && b._isFetched) {
            return 1;
        }

        if (a.needsAttention < b.needsAttention) {
            return -1;
        }

        if (a.needsAttention > b.needsAttention) {
            return 1;
        }

        if (a.contract.customer.name < b.contract.customer.name) {
            return -1;
        }

        if (a.contract.customer.name > b.contract.customer.name) {
            return 1;
        }

        if (a.customerCode < b.customerCode) {
            return -1;
        }

        if (a.customerCode > b.customerCode) {
            return 1;
        }

        if(a.id > b.id){
            return -1;
        }

        if(a.id < b.id){
            return 1;
        }

        return 0;
    }
    compareTruckLicense(a, b) {
        // Use toUpperCase() to ignore character casing
        const truckLicenseA = a.currentAssignment.truck.licensePlate.toUpperCase();
        const truckLicenseB = b.currentAssignment.truck.licensePlate.toUpperCase();
        const sortIsFetched = comparatorIsFetched(a, b);

        if (sortIsFetched !== undefined) {
            return sortIsFetched;
        }

        let comparison = 0;
        if (truckLicenseA > truckLicenseB) {
          comparison = 1;
        } else if (truckLicenseA < truckLicenseB) {
          comparison = -1;
        }
        if(comparison === 0){
            if(a.id > b.id){
                return -1;
            }

            if(a.id < b.id){
                return 1;
            }
        }
        return comparison;
    }

    comparatorArrival(a, b) {
        const sortIsFetched = comparatorIsFetched(a, b);

        if (sortIsFetched !== undefined) {
            return sortIsFetched;
        }

        // Current activity is more important than no current activity.
        if (a.currentActivity && !b.currentActivity) {
            return -1;
        }

        if (!a.currentActivity && b.currentActivity) {
            return 1;
        }

        if (!a.currentActivity && !b.currentActivity) {
            return 0;
        }

        // STATUS_ETA is less important.
        if (a.currentActivity.status !== STATUS_ETA && b.currentActivity.status === STATUS_ETA) {
            return -1;
        }

        if (a.currentActivity.status === STATUS_ETA && b.currentActivity.status !== STATUS_ETA) {
            return 1;
        }

        // Sort on actual arrival.
        if (a.currentActivity.actualArrivalDatetime < b.currentActivity.actualArrivalDatetime) {
            return -1;
        }

        if (a.currentActivity.actualArrivalDatetime > b.currentActivity.actualArrivalDatetime) {
            return 1;
        }

        // Sort on ETA.
        if (a.currentActivity.estimatedArrivalDatetime < b.currentActivity.estimatedArrivalDatetime) {
            return -1;
        }

        if (a.currentActivity.estimatedArrivalDatetime > b.currentActivity.estimatedArrivalDatetime) {
            return 1;
        }

        if (a.id > b.id){
            return -1;
        }

        if (a.id < b.id){
            return 1;
        }

        return 0;
    }
    comparatorHighValue(a, b) {
        const sortIsFetched = comparatorIsFetched(a, b);
        if (sortIsFetched !== undefined) {
            return sortIsFetched;
        }

        const hasTsrA = (a.currentActivity?.conditions ?? []).some(condition => condition.includes("tsr"));
        const hasTsrB = (b.currentActivity?.conditions ?? []).some(condition => condition.includes("tsr"));

        if (hasTsrA && !hasTsrB) {
            return -1;
        }
        if (!hasTsrA && hasTsrB) {
            return 1;
        }

        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    }
    /**
    * Generates a comparator function to sort messages within Teams channels based on their unread status.
    *
    * This function is designed to be used as a callback for the Array.prototype.sort method in JavaScript.
    * It creates two maps: one for messages sent to a general channel, and another for messages sent based on allocation IDs.
    *
    * The comparator function it returns sorts messages in the following priority order:
    * 1. Messages sent to the general channel with unread allocations.
    * 2. Messages sent to the general channel.
    * 3. Messages with unread allocations.
    *
    * Messages that do not fall into these categories are considered equal and their order remains unchanged.
    */
    generateComparatorUnreadTeamsChannelMessages(unreadMessages) {
        // Utilizing 'Map' objects for efficient data lookup:
        // The 'sentToGeneralMap' and 'allocationMap' are created using 'new Map' to enhance
        // the efficiency of data retrieval during the comparison process. Instead of performing
        // time-consuming array filtering operations each time we need to check if a message
        // belongs to a general channel or has an unread allocation, we pre-compute this information
        // and store it in Maps. These Maps act as quick lookup tables, where we can instantly
        // retrieve the status of a message based on 'contract.id' or 'allocation.id'. This strategy
        // significantly reduces the computational overhead, especially in scenarios where the
        // comparator function is invoked multiple times, such as during sorting operations.

        const sentToGeneralMap = new Map(unreadMessages
            .filter(message => message.sentToGeneral)
            .map(message => [message.contract.id, true]));

        const allocationMap = new Map(unreadMessages
            .filter(message => !message.sentToGeneral)
            .map(message => [message.allocation.id, true]));

        return (a, b) => {
            const aSentToGeneral = sentToGeneralMap.get(a.contract.id) || false;
            const bSentToGeneral = sentToGeneralMap.get(b.contract.id) || false;
            const aHasUnreadAllocation = allocationMap.get(a.id) || false;
            const bHasUnreadAllocation = allocationMap.get(b.id) || false;

            if (aSentToGeneral && aHasUnreadAllocation && !(bSentToGeneral && bHasUnreadAllocation)) {
                return -1;
            }
            if (bSentToGeneral && bHasUnreadAllocation && !(aSentToGeneral && aHasUnreadAllocation)) {
                return 1;
            }

            if (aSentToGeneral && !bSentToGeneral) {
                return -1;
            }
            if (!aSentToGeneral && bSentToGeneral) {
                return 1;
            }

            if (aHasUnreadAllocation && !bHasUnreadAllocation) {
                return -1;
            }
            if (!aHasUnreadAllocation && bHasUnreadAllocation) {
                return 1;
            }
            return 0;
        }
    }

    generateComparatorUnread(unreadMessagesByTruckStore) {
        return (a, b) => {
            const sortIsFetched = comparatorIsFetched(a, b);

            if (sortIsFetched !== undefined) {
                return sortIsFetched;
            }

            if (unreadMessagesByTruckStore.get(a.currentAssignment.truck.id) && !unreadMessagesByTruckStore.get(b.currentAssignment.truck.id)) {
                return -1;
            }

            if (!unreadMessagesByTruckStore.get(a.currentAssignment.truck.id) && unreadMessagesByTruckStore.get(b.currentAssignment.truck.id)) {
                return 1;
            }

            if (
                unreadMessagesByTruckStore.get(a.currentAssignment.truck.id) &&
                unreadMessagesByTruckStore.get(b.currentAssignment.truck.id)
            ) {
                const unreadCountA = (unreadMessagesByTruckStore.get(a.currentAssignment.truck.id).text || 0) + (unreadMessagesByTruckStore.get(a.currentAssignment.truck.id).form || 0);
                const unreadCountB = (unreadMessagesByTruckStore.get(b.currentAssignment.truck.id).text || 0) + (unreadMessagesByTruckStore.get(b.currentAssignment.truck.id).form || 0);

                if (unreadCountA > unreadCountB) {
                    return -1;
                }

                if (unreadCountA < unreadCountB) {
                    return 1;
                }
            }

            if(a.id > b.id){
                return -1;
            }

            if(a.id < b.id){
                return 1;
            }

            return 0;
        }
    }

    compareParkingBooking(a, b) {
        const isTravisDataSourceA = a.activities.models.some(activity => activity.parking.dataSource === 'travis');
        const isTravisDataSourceB = b.activities.models.some(activity => activity.parking.dataSource === 'travis');

        if (isTravisDataSourceA && !isTravisDataSourceB) {
            return -1;
        } else if (!isTravisDataSourceA && isTravisDataSourceB) {
            return 1;
        }

        const hasPaidParkingA = a.activities.models.some(activity => activity.type === 'parking' && !activity.parking.isFree);
        const hasPaidParkingB = b.activities.models.some(activity => activity.type === 'parking' && !activity.parking.isFree);
        const hasFreeParkingA = a.activities.models.some(activity => activity.type === 'parking' && activity.parking.isFree);
        const hasFreeParkingB = b.activities.models.some(activity => activity.type === 'parking' && activity.parking.isFree);

        if (hasPaidParkingA && !hasPaidParkingB) {
            return -1;
        } else if (!hasPaidParkingA && hasPaidParkingB) {
            return 1;
        } else if (hasFreeParkingA && !hasFreeParkingB) {
            return -1;
        } else if (!hasFreeParkingA && hasFreeParkingB) {
            return 1;
        }

        return 0;
    }
}
