import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, IconCheck, IconHighlightOff, IconHelpOutline } from 're-cy-cle';
import ModalPlanningCustomerSelectDocument from 'screen/ModalPlanningCustomerSelectDocument';
import { Document, TYPE_TEMPERATURE_PRINT } from 'store/Document';
import { Activity, ActivityStore } from 'store/Activity';
import moment from 'moment';
import { api } from 'store/Base';
import { Table, Popup, Loader, Modal } from 'semantic-ui-react';
import DroppableButton from 'component/DroppableButton';
import { showSaveNotification } from '@code-yellow/spider';
import { IconButton  } from '@code-yellow/spider';
import ScanRequestModal from 'component/Document/ScanRequestModal';

@observer
class PreviousLoadActivities extends Component {
    // {copy-previous-load-store}
    @observable previousLoadActivityStore = new ActivityStore({
        relations: ['location'],
        params: {
            '.type': 'load',
            order_by: '-ordered_arrival_datetime_from',
        },
    });

    static propTypes = {
        activity: PropTypes.instanceOf(Activity).isRequired,
        label: PropTypes.string.isRequired,
    }

    renderLoadActivities = () => {
        if (this.previousLoadActivityStore.isLoading) {
            return <Loader active />;
        }

        if (this.previousLoadActivityStore.length === 0) {
            return 'No load found';
        }

        return this.previousLoadActivityStore.map(activity => (
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{t('activity.field.id.label')}</Table.Cell>
                        <Table.Cell>{activity.id}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t('location.field.city.label')}</Table.Cell>
                        <Table.Cell>{activity.location.city}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t('activity.field.instructions.label')}</Table.Cell>
                        <Table.Cell>{activity.instructions}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        ));
    }

    render() {
        const { label } = this.props;

        return (
            <Popup
                position="top center"
                popperDependencies={[!this.previousLoadActivityStore.isLoading]}
                trigger={<span>{label}</span>}
                onOpen={() => {
                    // {copy-previous-load-store}
                    delete this.previousLoadActivityStore.params['.proposed_activity.internal_order_reference'];
                    delete this.previousLoadActivityStore.params['.order_reference'];

                    if (!this.props.activity.proposedActivity.isNew) {
                        this.previousLoadActivityStore.params['.proposed_activity.internal_order_reference'] = this.props.activity.proposedActivity.internalOrderReference;
                    } else {
                        this.previousLoadActivityStore.params['.order_reference'] = this.props.activity.orderReference;
                    }

                    this.previousLoadActivityStore.fetch();
                }}
            >
                {this.renderLoadActivities()}
            </Popup>
        );
    }
}

@observer
export default class ActivityFinalizeDocument extends Component {
    static propTypes = {
        activity: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        onDocumentApprove: PropTypes.func.isRequired,
    };

    static contextTypes = {
        viewStore: PropTypes.object,
    };

    @observable droppable = false;
    @observable scanRequestModalOpen = false;

    uploadImage = files => {
        const { type } = this.props;

        files.forEach((file) =>{
            const doc = new Document({
                type,
                status: 'approved by planner',
                activity: this.props.activity.toJS(),
                scannedAt: moment(),
                truck: this.props.activity.assignment.truck.toJS(),
            }, {
                relations: ['activity', 'truck'],
            });

            if (file.type === 'application/pdf') {
                doc.setInput('pdfFile', file);
            } else {
                doc.setInput('file', file);
            }

            doc.save().then(() => {
                this.props.activity.documents.chooseDocument(doc);
                this.props.onDocumentApprove();
                showSaveNotification();
            });
        });
    };

    openModal = () => {
        this.context.viewStore.setModal({
            render: ModalPlanningCustomerSelectDocument,
            activity: this.props.activity,
            type: this.props.type,
            onDocumentApprove:this.props.onDocumentApprove,
        });
    };

    requestScan = (type, reason) => {
        const { activity } = this.props;
        api.post(`activity/${activity.id}/request_new_scan/`, {
                type: type,
                reason: reason
            })
            .then(() => {
                this.props.viewStore.showNotification({
                    dismissAfter: 4000,
                    message: t('form.notifications.requestScanSuccess'),
                });
            })
    };

    renderIcon = () => {
        const { activity, type } = this.props;
        const activityDocument = activity.documents.filter(d => d.status === 'approved by planner' && d.type === type);

        if (activityDocument.length > 0) {
            return <IconCheck />;
        }

        if (activity.documents.length > 0) {
            return <IconHelpOutline />;
        }

        return <IconHighlightOff />;
    };

    render() {
        const { activity, type } = this.props;
        const activityDocument = activity.documents.findByType(type);
        const wasScanRequested = activity.documents.wasScanRequestSent(type);

        return (
            <Table.Row>
                <Table.Cell collapsing>
                    {this.renderIcon()}
                </Table.Cell>
                <Table.Cell data-test-req-doc>
                    <PreviousLoadActivities
                        label={t(`document.field.type.options.${type}`)}
                        activity={activity}
                    />
                </Table.Cell>
                <Table.Cell collapsing>
                    <DroppableButton data-test-upload-document-modal
                        onDrop={this.uploadImage}
                        accept={type === TYPE_TEMPERATURE_PRINT ? 'application/pdf' : 'image/jpeg, image/png, application/pdf'}
                        title={t('activity.field.requiredDocuments.upload')}
                    />
                    {!!activityDocument && (
                        <IconButton
                            link
                            name="download"
                            href={`${process.env.REACT_APP_CY_FRONTEND_API_BASE_URL}activity/${activity.id}/all_documents/`}
                            title={t('activity.field.requiredDocuments.downloadAll')}
                        />
                    )}
                    <IconButton data-test-show-document-modal
                        name="search"
                        onClick={this.openModal}
                        title={t('activity.field.requiredDocuments.searchModal')}
                    />
                    {!!activityDocument && !wasScanRequested && (
                        <React.Fragment>
                            <Modal
                                open={this.scanRequestModalOpen}
                                onClose={() => this.scanRequestModalOpen = false}
                            >
                                <ScanRequestModal
                                    type={type}
                                    close={() => this.scanRequestModalOpen = false}
                                    apply={this.requestScan}
                                />
                            </Modal>
                            <Button data-test-request-scan
                                onClick={() => this.scanRequestModalOpen = true}
                            >
                                {t('planning.activityFinalize.requestScan')}
                            </Button>
                        </React.Fragment>
                    )}
                    {!activityDocument && (
                        <Button
                            onClick={() => this.requestScan(type, '')}
                        >
                            {t('planning.activityFinalize.requestScan')}
                        </Button>
                    )}
                </Table.Cell>
            </Table.Row>
        );
    }
}
