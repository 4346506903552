import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, Button } from 'semantic-ui-react';
import OverviewDetailsActivityFinalize from 'container/PlanningCustomer/OverviewDetails/ActivityFinalize';
import { CancelButton, SaveButton  } from '@code-yellow/spider';
import { RightDivider  } from '@code-yellow/spider';
import { parseBackendErrorMessages } from 'helpers';
import { showSaveNotification } from '@code-yellow/spider';
import { CONTEXT_TYPE_ACTIVITY } from 'store/BEPTicket';
import { User } from 'store/User';
import BEPContextButtons from 'component/BEPTicket/BEPContextButtons';
import { hasPermission, currentUser } from 'helpers/permission';

@currentUser
@observer
export default class ModalActivityFinalize extends Component {
    static propTypes = {
        activityId: PropTypes.number.isRequired,
        viewStore: PropTypes.object.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
        trigger: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        afterSave: PropTypes.func,
    };

    @observable isOpen = false;
    @observable isLoading = false;
    @observable isFinalized = false;
    @observable isInvoiced = false;
    @observable finalizeRef = React.createRef();
    @observable activity = null;


    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    afterLoadActivity = activity => {
        if (activity.finalizedAt !== null) {
            this.isFinalized = true;
        }

        if (activity.invoiceLineItems && activity.invoiceLineItems.length > 0 ) {
            this.isInvoiced = true;
        }

        this.forceUpdate()
    }

    hasPermission = perms => {
        return hasPermission(perms);
    };

    handleClose = () => {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        } else {
            this.close();
        }
    };

    unFinalize = () => {
        const { afterSave } = this.props;
        this.isLoading = true;

        return this.finalizeRef.current.activity.unFinalize()
            .then(afterSave)
            .then(showSaveNotification)
            .then(this.handleClose)
            .then(() => this.isLoading = false);
    }

    save = () => {
        this.isLoading = true;

        return this.finalizeRef.current.save()
            .then(this.handleClose)
            .catch(response => {
                console.log('ERRRR', response);
                const messages = parseBackendErrorMessages(response.data.errors);

                messages.forEach(message => {
                    this.props.viewStore.showNotification({
                        key: message,
                        type: 'error',
                        dismissAfter: 4000,
                        message,
                    });
                });
            })
            .then(() => this.isLoading = false);
    }

    renderContent() {
        const { activityId, afterSave, viewStore } = this.props;

        if (!this.isOpen) {
            return null;
        }

        return (
            <OverviewDetailsActivityFinalize showMap showCustomFinalizedModule hideSkipButton hideRegenerateTemperaturePrintButton hideSaveButton hideUnfinalizeButton
                activityId={activityId}
                viewStore={viewStore}
                afterSave={afterSave}
                ref={this.finalizeRef}
                afterLoadActivity={this.afterLoadActivity}
            />
        );
    }

    render() {
        const { activityId, afterSave, viewStore, currentUser, trigger, ...rest } = this.props;
        const activity = this.finalizeRef.current ? this.finalizeRef.current.activity : null;

        return (
            <Modal
                centered={false}
                open={this.isOpen}
                onClose={this.close}
                size="large"
                trigger={trigger({ onClick: this.open })}
                {...rest}
            >
                <Modal.Header>
                    {activity && (
                        <BEPContextButtons
                            content={t('planning.customer.panelTitles.activityDetails')}
                            currentUser={currentUser}
                            contextType={CONTEXT_TYPE_ACTIVITY}
                            contextActivity={activity}
                        />
                    )}
                </Modal.Header>
                <Modal.Content scrolling>
                    {this.renderContent()}
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton data-test-cancel-button onClick={this.close} />
                    {this.hasPermission(['activity.unfinalize_activity:all']) && this.isFinalized && (
                        <Button data-test-unfinalize-button
                            loading={this.isLoading}
                            disabled={this.isInvoiced}
                            title={this.isInvoiced ? t('activity.invoicedActivity') : ''}
                            onClick={this.unFinalize}
                        >
                            {t('form.unfinalizeButton')}
                        </Button>
                    )}
                    <RightDivider />
                    <SaveButton data-test-save-button primary
                        loading={this.isLoading}
                        onClick={this.save}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}
