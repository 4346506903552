import React, { Component } from 'react';
import { useMemo } from 'react'
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxTypes } from 'mobx-react';
import { ApplyButton  } from '@code-yellow/spider';
import { observable, computed } from 'mobx';
import { TruckPositionStore } from 'store/TruckPosition';
import { PointOfInterestStore } from 'store/Location/PointOfInterest';
import Map, { HybridTileLayer, TileLayer, truckIcon } from 'component/Location/Map';
import { LayersControl, Marker, Polyline, FeatureGroup, ScaleControl, Popup, Circle, Polygon } from 'react-leaflet';
import { useMapEvents } from 'react-leaflet'
import { latLng } from 'leaflet';
import activityMarker from 'component/Location/ActivityMarker';
import closestLocation from 'helpers/closestLocation';
import styled from 'styled-components';
import { MessageStore } from 'store/Message';
import { HomebaseStore } from 'store/Homebase';
import { Loader } from 'semantic-ui-react';
import ParkingMapPoint from 'container/Parking/ParkingMapPoint';
import { Button, Modal } from 'semantic-ui-react';
import { RightDivider  } from '@code-yellow/spider';
import ParkingMapModal from './ParkingMapModal';
import { PARKING_STATUS_REJECTED, ParkingStore } from 'store/Parking';
import { Activity } from 'store/Activity';
import { IconButton  } from '@code-yellow/spider';
import { PetrolStation } from 'store/PetrolStation';
import moment from 'moment';
import { RestrictedZoneStore } from 'store/RestrictedZone';
import { POINTS } from 'container/RestrictedZone/Edit';
import RestrictedZonePolygon from './RestrictedZonePolygon';
import L from 'leaflet'

const Fragment = React.Fragment;
export const BOEK_HQ = [52.308594, 17.056734];
const COLOR_DRIVEN = '#3388fe'; // Blue
const COLOR_PLANNED = '#14772d'; // Green
const COLOR_TO_PLANNED = 'black';
export const ROUTE_COLORS = ['#0b409c', '#b83b5e', '#616f39', '#679186'];

const ParkingMapButton = styled(Button)`
    position: absolute ;
    top: 17%;
    left: 0.5%;
    scale: 70%;
    z-index: 999;
    border: 1px solid black;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;

export const TopRight = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 999;
    background: #eee;
    padding: 2px;
`;

export function fixZoom(bounds){

    let minLat = bounds[0][0];
    let minLng = bounds[0][1];
    let maxLat = minLat;
    let maxLng = minLng;

    for (let index = 1; index < bounds.length; index++){
        const lat = bounds[index][0];
        const lng = bounds[index][1];

        if (lat < minLat) {
            minLat = lat;
        }
        if (lat > maxLat) {
            maxLat = lat;
        }
        if (lng < minLng) {
            minLng = lng;
        }
        if (lng > maxLng) {
            maxLng = lng;
        }
    }

    if (minLat === maxLat || minLng === maxLng) {
        bounds.push([minLat - 0.05, minLng - 0.05]);
        bounds.push([minLat + 0.05, minLng + 0.05]);
    }
}

function SetBoundsRectangles({ bounds, onClick }) {
    const map = useMapEvents({
        click(e) {
            if (onClick) {
                onClick(e);
            }
        },
      })

    useMemo(() => {
        if (bounds) {
            map.fitBounds(bounds, { padding: [20, 20] })
        }
    }, [bounds]);

  return (
    <>
    </>
  )
}


SetBoundsRectangles.propTypes = {
  bounds: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};


@observer
export default class TruckMap extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(TruckPositionStore),
        activityRoute: MobxTypes.arrayOrObservableArray,
        activityRouteForParking: MobxTypes.arrayOrObservableArray,
        activityMarkersForParking: MobxTypes.arrayOrObservableArray,
        routes: MobxTypes.arrayOrObservableArray, // Used for displaying multiple routes, see https://phabricator.codeyellow.nl/T15630#424979
        truckRoute: MobxTypes.arrayOrObservableArray,
        activityMarkers: MobxTypes.arrayOrObservableArray.isRequired,
        selectedActivity: PropTypes.object,
        pointOfInterestStore: PropTypes.instanceOf(PointOfInterestStore).isRequired,
        pointOfInterestParkingStore: PropTypes.instanceOf(ParkingStore),
        range: PropTypes.number, // When a range in meters is given, this will render a circle around the truck.
        autoZoomAndScale: PropTypes.bool,
        onClick: PropTypes.func,
        onMouseOverTruckPosition: PropTypes.func,
        onClickTruckPosition: PropTypes.func,
        onClickPointOfInterest: PropTypes.func,
        truck: PropTypes.object,
        driverPoiStore: PropTypes.instanceOf(MessageStore),
        homebaseStore: PropTypes.instanceOf(HomebaseStore),
        onClickHomebase: PropTypes.func,
        isLoading: PropTypes.bool.isRequired,
        parkingOverviewMap: PropTypes.bool,
        parkingModalMap: PropTypes.bool,
        onClickParking: PropTypes.func,
        activity: PropTypes.instanceOf(Activity),
        onClickParkingFromModal: PropTypes.func,
        openParking: PropTypes.string,
        freeParkingOpenSet: PropTypes.object,
        parkingBookableSet: PropTypes.object,
        travisBookableSet: PropTypes.object,
        restrictedZonePolygon: MobxTypes.arrayOrObservableArray,
        restrictedZoneStore: PropTypes.instanceOf(RestrictedZoneStore),
    };

    static defaultProps = {
        activityMarkers: [],
        isLoading: false,
    };

    // {almost-copy-pasta-lat-lng}
    getLatLng(position) {
        // Show boekestijn HQ if there is nothing interesting to see
        if (!position || (position && !position.point)) {
            return BOEK_HQ;
        }

        if (typeof position.point.lat === 'number') {
            return [position.point.lat, position.point.lng];
        }

        return [parseFloat(position.point.lat), parseFloat(position.point.lng)];
    }

    // When props are updated, the bounds of the map will be reset.
    // If the user meanwhile has zoomed/dragged, we do not want to reset the bounds
    @observable userHasZoomed = false;
    @observable refRange = false;

    @observable clickedTruckPosition = null;
    @observable isReady = false;

    @observable isOpen = false;
    @observable secondIsLoading = true;
    open = () => {this.isOpen = true}
    close = () => {
        this.isOpen = false
    };

    @observable parkingOpenId = null;
    @observable hoverOnExpandButton = false;

    applyParkingFromExtendedMap(parkingId) {
        this.close()
        const spanElements = document.querySelectorAll('span[data-test-marker-parking]');

        spanElements.forEach(span => {
            if (span.getAttribute('data-test-marker-parking') === String(parkingId)) {
                span.click();
            }
        })
    }

    /**
     * Either map is controlled using the prop autoZoomAndScale, or not and uses
     * this.userHasZoomed.
     */
    @computed get shouldAutoZoom() {
        if ('autoZoomAndScale' in this.props) {
            return this.props.autoZoomAndScale;
        }

        return !this.userHasZoomed;
    }

    setUserZoom = e => {
        this.userHasZoomed = true;
    };

    componentDidMount() {
        if (L.DomUtil.get('map')) {
            L.DomUtil.get('map')._leaflet_id = null
        }
    }

    useCurrentPosition(truck){
        if (truck && truck.currentPosition){
            const point = truck.currentPosition.point;
            const lat = parseFloat(point.lat);
            return !isNaN(lat);
        } else {
            return false;
        }
    }

    parsePoiMessage(text) {
        var array = text.split(/\r?\n/);
        let description;
        let latitude;
        let longitude;

        if (array.length > 0) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].includes('DESTINATION:')) {
                    description = array[i].replace('DESTINATION: ', '');
                } else if (array[i].includes('LOCATION:')) {
                    var location = array[i].trim().replace('LOCATION: ', '').split(',');
                    latitude = parseFloat(location[0]);
                    longitude = parseFloat(location[1]);
                }
            }
        }

        return {
            'description': description,
            'location': [latitude, longitude]
        };
    }

    handleRestrictedZonePolygon(restrictedZone) {
        let polygon = []
        POINTS.map(point => {
            polygon.push([
                restrictedZone[`point${point}`].lat,
                restrictedZone[`point${point}`].lng
            ]);
        })
        return polygon
    }

    render() {
        const { store, activityRoute, activityRouteForParking, routes, truckRoute, truck, activityMarkers, activityMarkersForParking, selectedActivity, onClick, pointOfInterestStore, pointOfInterestParkingStore, onClickPointOfInterest, range, onClickTruckPosition, driverPoiStore, homebaseStore, onClickHomebase, isLoading, parkingModalMap, parkingOverviewMap, onClickParking, activity, onClickParkingFromModal, freeParkingOpenSet, parkingBookableSet, travisBookableSet, restrictedZonePolygon, restrictedZoneStore } = this.props;
        const truckPositions = store ? store.map(this.getLatLng) : [];
        const activityRoutePositions = activityRoute && activityRoute.map(this.getLatLng);
        const activityRoutePositionsForParking = activityRouteForParking && activityRouteForParking.map(this.getLatLng)
        const truckRoutePositions = truckRoute && truckRoute.map(this.getLatLng);
        let lastPositionLatlng;
        if (this.useCurrentPosition(truck)){
            lastPositionLatlng = this.getLatLng(truck.currentPosition)
        } else {
            lastPositionLatlng = store && store.length > 0 ? this.getLatLng(store.lastPosition()) : null;
        }
        const markerBounds = activityMarkers.map(m => this.getLatLng(m.location));
        let bounds = [];

        if (lastPositionLatlng) {
            bounds.push(lastPositionLatlng);
        }

        bounds = bounds.concat(markerBounds);

        if (pointOfInterestStore) {
            bounds = bounds.concat(pointOfInterestStore.map(this.getLatLng));
        }

        if (lastPositionLatlng && range) {
            bounds.push(latLng(lastPositionLatlng).toBounds(1.50 * range));
        }

        if (pointOfInterestParkingStore) {
            bounds = bounds.concat(pointOfInterestParkingStore.map(parking => this.getLatLng(parking.location)));
        }

        // Leave it here for now.. option do set zoom..
        if (pointOfInterestParkingStore && activity && lastPositionLatlng) {
            // this will set always zoom 500km radius on current truck position
            bounds = [lastPositionLatlng, latLng(lastPositionLatlng).toBounds(500000)]
        } else if (pointOfInterestParkingStore) {
            bounds = bounds.concat(pointOfInterestParkingStore.map(parking => this.getLatLng(parking.location)));
        }

        if (activityMarkersForParking) {
            bounds = bounds.concat(activityMarkersForParking.map(m => this.getLatLng(m.location)))
        }

        if (bounds.length < 1) {
            // Show boekestijn HQ if there is nothing interesting to see
            bounds.push(BOEK_HQ);
        }
        fixZoom(bounds);

        if (isLoading) {
            return <Loader active />;
        }

        // {perhaps-unify-maps}
        return (
            <Map
                id="map"
                data-test-is-ready={this.isReady}
                whenReady={() => {
                    this.isReady = true;
                }}
                bounds={this.shouldAutoZoom ? bounds : undefined}
                boundsOptions={{ padding: [20, 20] }}
                onDragstart={this.setUserZoom}
                attributionControl={false}
                touchExtend={false} // Prevents Uncaught TypeError: Cannot read property '_leaflet_pos' of undefined
            >
                <SetBoundsRectangles
                    bounds={this.shouldAutoZoom ? bounds : undefined}
                    onClick={({ latlng }) => {
                        if (onClick && !this.hoverOnExpandButton) {
                            // Prevents map from jumping around.
                            this.setUserZoom();
                            onClick(latlng);
                        }
                    }}
                />
                {/* Hidden div to help tests for waiting that the map is ready. */}
                {this.isReady && <div style={{ display: 'none' }} data-test-is-ready />}
                <LayersControl position="bottomleft">
                    <LayersControl.BaseLayer checked name={t('planning.maps.layers.map')} >
                        <TileLayer />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name={t('planning.maps.layers.hybrid')} >
                        <HybridTileLayer />
                    </LayersControl.BaseLayer>
                </LayersControl>
                <FeatureGroup>
                    {this.clickedTruckPosition && onClickTruckPosition && (
                        <Marker
                            position={this.clickedTruckPosition}
                            icon={activityMarker({ type: 'crosshairs', kind: 'boek stop' }, true)}
                            zIndexOffset={this.hasPermission(['boekestijn.manage_parking:dispatcher', 'boekestijn.manage_parking:all']) ? 999 : 2}
                            onClick={() => {
                                const closest = closestLocation(this.clickedTruckPosition.lat, this.clickedTruckPosition.lng, truckPositions.map((latlng, i) => [i, ...latlng]));

                                if (closest) {
                                    onClickTruckPosition(store.at(closest[0]));
                                    this.clickedTruckPosition = null;
                                }
                            }}
                        />
                    )}
                    {!!truckRoutePositions && (
                        <Polyline
                            positions={truckRoutePositions}
                            weight={3}
                            color={COLOR_TO_PLANNED}
                        />
                    )}
                    {!!routes && routes.map((route, i) => (
                        <Polyline
                            positions={route.map(this.getLatLng)}
                            weight={3}
                            color={ROUTE_COLORS[i]}
                        />
                    ))}
                    {store && store.length > 0 && (
                        <Fragment>
                            <Polyline
                                positions={truckPositions}
                                weight={4}
                                color={COLOR_DRIVEN}
                                onMouseover={({ latlng }) => {
                                    const { onMouseOverTruckPosition } = this.props;
                                    const closest = closestLocation(latlng.lat, latlng.lng, truckPositions.map((latlng, i) => [i, ...latlng]));

                                    if (closest && onMouseOverTruckPosition) {
                                        onMouseOverTruckPosition(store.at(closest[0]));
                                    }
                                }}
                                onClick={({ latlng }) => {
                                    this.clickedTruckPosition = latlng;
                                }}
                            />
                        </Fragment>
                    )}
                    {!!restrictedZonePolygon  && (
                        <Polygon pathOptions={{color:'red'}} stroke={false} positions={[restrictedZonePolygon]}/>
                    )}
                    {restrictedZoneStore && restrictedZoneStore.map((restrictedZone, index) => {
                        const props = {
                            key: restrictedZone.id,
                            positions: [this.handleRestrictedZonePolygon(restrictedZone)],
                            restrictedZone: restrictedZone,
                        };
                        return <RestrictedZonePolygon {...props} />;
                    })}
                    {!!activityRoutePositions && (
                        <Polyline
                            positions={activityRoutePositions}
                            weight={4}
                            color={COLOR_PLANNED}
                        >
                        </Polyline>
                    )}
                    {!!activityRoutePositionsForParking && (
                        <Polyline
                            positions={activityRoutePositionsForParking}
                            weight={2}
                            color={COLOR_PLANNED}
                        >
                        </Polyline>
                    )}
                </FeatureGroup>
                <FeatureGroup>
                    {homebaseStore && homebaseStore.map(homebase => (
                        <Marker
                            position={this.getLatLng(homebase)}
                            icon={activityMarker({ type: 'homebase', kind: 'boek stop' })}
                            zIndexOffset={2}
                        >
                            <Popup>
                                <b>{homebase.name}</b>
                                {` ${homebase.point.lat}, ${homebase.point.lng}`}
                                {onClickHomebase && <ApplyButton onClick={() => onClickHomebase(homebase)} />}
                            </Popup>
                        </Marker>
                    ))}
                    {pointOfInterestStore && pointOfInterestStore.map(poi => {
                        const isPriceFromToday = poi instanceof PetrolStation && moment().format('YYYY-MM-DD') === poi.priceFrom;
                        const dateColor = isPriceFromToday ? "" : "red";
                           return <Marker
                                key={poi.id || poi.cid}
                                position={this.getLatLng(poi)}
                                icon={activityMarker({ type: 'tanking', kind: 'boek stop', color: poi.color, price: poi.price,'data-test-marker-poi': poi.id }, false)}
                                zIndexOffset={2}
                            >
                                <Popup>
                                    <b>{poi.name}</b><br />
                                    {poi instanceof PetrolStation ? <span>Price from: <span data-test-date-color style={{color: dateColor}}>{poi.priceFrom}</span></span> : ""}
                                    {poi instanceof PetrolStation ? <br /> : ""}
                                    {`${poi.point.lat}, ${poi.point.lng}`}
                                    {onClickPointOfInterest && <ApplyButton onClick={() => onClickPointOfInterest(poi)} />}
                                </Popup>
                            </Marker>

                        }
                    )}
                    {pointOfInterestParkingStore && pointOfInterestParkingStore.map(parking => {
                        if ((!!parking.travisId && travisBookableSet && travisBookableSet.size > 0  && travisBookableSet.has(parking.travisId)) || (parking.status === PARKING_STATUS_REJECTED)) {
                            return (
                                <Marker
                                    key={parking.id || parking.cid}
                                    position={this.getLatLng(parking.location)}
                                    icon={activityMarker({ type: 'parking', kind: 'boek stop', color: parking.color, 'certification': parking.certification,'data-test-marker-parking': parking.id, 'unbookable':false }, false)}
                                    zIndexOffset={999}
                                >
                                    <ParkingMapPoint
                                        bookable={true}
                                        parking={parking}
                                        parkingModalMap={parkingModalMap}
                                        parkingOverviewMap={parkingOverviewMap}
                                        onClickParking={onClickParking}
                                        activity={selectedActivity ? selectedActivity : activity}
                                        onClickParkingFromModal={onClickParkingFromModal}
                                    />
                                </Marker>
                            )
                        } else if ((parkingBookableSet && parkingBookableSet.size > 0  && parkingBookableSet.has(parking.id)) || (parking.status === PARKING_STATUS_REJECTED) || (freeParkingOpenSet && freeParkingOpenSet.size > 0 && freeParkingOpenSet.has(parking.id))) {
                            return (
                                <Marker
                                    key={parking.id || parking.cid}
                                    position={this.getLatLng(parking.location)}
                                    icon={activityMarker({ type: 'parking', kind: 'boek stop', color: parking.color, 'certification': parking.certification,'data-test-marker-parking': parking.id, 'unbookable':false }, false)}
                                    zIndexOffset={999}
                                >
                                    <ParkingMapPoint
                                        bookable={true}
                                        parking={parking}
                                        parkingModalMap={parkingModalMap}
                                        parkingOverviewMap={parkingOverviewMap}
                                        onClickParking={onClickParking}
                                        activity={selectedActivity ? selectedActivity : activity}
                                        onClickParkingFromModal={onClickParkingFromModal}
                                    />
                                </Marker>
                            )
                        } else {
                            return (
                                <Marker
                                    key={parking.id || parking.cid}
                                    position={this.getLatLng(parking.location)}
                                    icon={activityMarker({ type: 'parking', kind: 'boek stop', color: parking.color, 'certification': parking.certification,'data-test-marker-parking': parking.id, 'unbookable': (parkingOverviewMap ? false : true) }, false)}
                                    zIndexOffset={999}
                                >
                                    <ParkingMapPoint
                                        bookable={false}
                                        parking={parking}
                                        parkingModalMap={parkingModalMap}
                                        parkingOverviewMap={parkingOverviewMap}
                                        onClickParking={onClickParking}
                                        activity={selectedActivity ? selectedActivity : activity}
                                        onClickParkingFromModal={onClickParkingFromModal}
                                    />
                                </Marker>
                            )
                        }
                    }
                    )}
                    {driverPoiStore && driverPoiStore.map(message => {
                        var poi = this.parsePoiMessage(message.text);
                        return (<Marker position={poi['location']}>
                            <Popup>
                                {poi['description']}
                            </Popup>
                        </Marker>
                        );
                    })}
                    {activityMarkers.filter(a => a.kind === 'activity').map((anActivity, i) => {
                        return (
                            <div data-test-marker-activity>
                                <Marker
                                    position={this.getLatLng(anActivity.location)}
                                    icon={activityMarker(anActivity, selectedActivity && selectedActivity.id === anActivity.id)}
                                    zIndexOffset={2}
                                />
                            </div>
                        );
                    })}
                    {activityMarkers.filter(a => a.kind !== 'activity').map(stop => {
                        const props = {
                            key: stop.id,
                            icon: activityMarker(stop, selectedActivity && selectedActivity.id === stop.id),
                            position: this.getLatLng(stop.location),
                            zIndexOffset: 2,
                        };
                        return <Marker {...props} />;
                    })}
                    {activityMarkersForParking && activityMarkersForParking.filter(a => a.kind === 'activity').map((anActivity, i) => {
                        return (
                            <div data-test-marker-activity>
                                <Marker
                                    position={this.getLatLng(anActivity.location)}
                                    icon={activityMarker(anActivity, selectedActivity && selectedActivity.id === anActivity.id)}
                                    zIndexOffset={2}
                                />
                            </div>
                        );
                    })}
                    {activityMarkersForParking && activityMarkersForParking.filter(a => a.kind !== 'activity').map(stop => {
                        const props = {
                            key: stop.id,
                            icon: activityMarker(stop, selectedActivity && selectedActivity.id === stop.id),
                            position: this.getLatLng(stop.location),
                            zIndexOffset: 2,
                        };
                        return <Marker {...props} />;
                    })}
                    {lastPositionLatlng && (
                        <React.Fragment>
                            <Marker
                                position={lastPositionLatlng}
                                icon={truckIcon}
                                zIndexOffset={999}
                                // Setting a data-test-* attribute seems to be near impossible, so abuse alt for this.
                                alt={`data-test-truck-position=${lastPositionLatlng[0]},${lastPositionLatlng[1]}`}
                            />
                            {range && <Circle ref={ref => this.refRange = ref} center={lastPositionLatlng} radius={range} />}
                        </React.Fragment>
                    )}
                    <ScaleControl imperial={false} />
                </FeatureGroup>
                {pointOfInterestParkingStore && !parkingModalMap && !parkingOverviewMap && activity.isNew &&
                    <Modal
                        centered={false}
                        dimmer='blurring'
                        open={this.isOpen}
                        onClose={() => {
                            this.close()
                            this.applyParkingFromExtendedMap(this.parkingOpenId)
                        }}
                        closeOnDimmerClick={true}
                        style={{ width: '1800px' }}
                        trigger={(
                            <ParkingMapButton icon='expand' style={{ background: 'white', color:'black', boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)' }} onClick={this.open} onMouseEnter={() => this.hoverOnExpandButton = true} onMouseLeave={() => this.hoverOnExpandButton = false}/>
                        )}
                    >
                        <Modal.Header style={{ display: 'flex' }}>Parking map extend <RightDivider /> <IconButton name='x' onClick={() => this.applyParkingFromExtendedMap(this.parkingOpenId)} /></Modal.Header>
                        <Modal.Content>
                            <ParkingMapModal
                                onClick={onClick}
                                activityMarkersForParking={activityMarkersForParking}
                                activityRouteForParking={activityRouteForParking}
                                pointOfInterestParkingStore={pointOfInterestParkingStore}
                                parkingModalMap={parkingModalMap}
                                onClickParkingFromModal={(parkingId) => this.parkingOpenId = parkingId}
                                store={store}
                                onClickParking={onClickParking}
                                activity={activity}
                                onClickCollapseButton={() => {this.close(); this.applyParkingFromExtendedMap(this.parkingOpenId)}}
                                pointOfInterestStore={pointOfInterestStore}
                                freeParkingOpenSet={freeParkingOpenSet}
                                parkingBookableSet={parkingBookableSet}
                                travisBookableSet={travisBookableSet}
                            />
                        </Modal.Content>
                    </Modal>
                }
            </Map>
        );
    }
}
