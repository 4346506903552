import axios from 'axios';
import { pick, debounce, upperFirst } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Activity, ActivityStore } from 'store/Activity';
import { STATUS_APPLIED } from 'store/EquipmentChange';
import { Allocation } from 'store/Allocation';
import { ACTION_DELAY } from 'helpers';
import { Table, Icon, Popup } from 'semantic-ui-react';
import { theme } from 'styles';
import styled from 'styled-components';
import { FormNumberInput  } from '@code-yellow/spider';
import { SuperText } from 're-cy-cle';

const TableCell = styled(Table.Cell)`
    text-align: center !important;
`;

const StyledFormNumberInput = styled(FormNumberInput)`
    input {
        width: 4em;
        text-align: center !important;
        margin-left: 1px !important;
        margin-right: 1px !important;
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
`;

const TrailerChangeDropAllSpan = styled.span`
    white-space: nowrap;
    color: #F2711C;
`;

@observer
export default class TrackedEquipmentTable extends Component {
    static propTypes = {
        allocation: PropTypes.instanceOf(Allocation).isRequired,
        activity: PropTypes.instanceOf(Activity).isRequired,
        previousActivityStore: PropTypes.instanceOf(ActivityStore).isRequired,
        trackingTarget: PropTypes.string.isRequired,
        disabled: PropTypes.bool,

        /**
         * When false, autosave will add a param which will block having
         * negative `after` value.
         *
         * @type {Boolean}
         */
        allowNegative: true,
    };

    // TODO: this is a very temporary solution for T26992.
    // Rather do not use UNSAFE_componentWillMount
    //
    // Also only compute if equipmentChanges, TrackedEquipment changes
    UNSAFE_componentWillMount() {
        const { allocation, activity } = this.props

        allocation.contract.trackedEquipments.forEach(trackedEquipment => {
            const equipmentChange = activity.equipmentChanges.find(e => e.equipmentType.id === trackedEquipment.equipmentType.id && !e.isCorrection);
            const equipmentChangeCorrection = activity.equipmentChanges.find(e => e.equipmentType.id === trackedEquipment.equipmentType.id && e.isCorrection);

            if (!equipmentChange) {
                activity.equipmentChanges.add({
                    changeTarget: trackedEquipment.trackingTarget,
                    equipmentType: { id: trackedEquipment.equipmentType.id },
                    isCorrection: false,
                });
            }

            if (!equipmentChangeCorrection) {
                activity.equipmentChanges.add({
                    changeTarget: trackedEquipment.trackingTarget,
                    equipmentType: { id: trackedEquipment.equipmentType.id },
                    isCorrection: true,
                });
            }
        });
    }

    autosave(immediate = false) { // TODO: autosave becomes quite slow especially for ActivityDetails.js
        if (this.cancelRequest) {
            this.cancelRequest();
            this.cancelRequest = undefined;
        }

        if (immediate) {
            return this.save();
        }

        return this.saveDebounced();
    }

    save = () => {
        const { activity } = this.props;

        // Make a clone, to specifically save equipment changes. When a save is
        // successful, it will clear out hasUserChanges. The next save (for
        // example change status) will therefor save nothing...
        // See also https://phabricator.codeyellow.nl/T19354#487704
        const activityForEquipmentChanges = new Activity(
            pick(activity.toJS(), 'id', 'equipmentChanges'),
            { relations: ['equipmentChanges.equipmentType'] }
        );

        activityForEquipmentChanges.markChanged('equipmentChanges');
        activityForEquipmentChanges.equipmentChanges.forEach(ec => ec.markChanged('load', 'unload'));

        activity.wrapPendingRequestCount(activityForEquipmentChanges.save({
            url: activityForEquipmentChanges.url + 'equipment_changes/',
            onlyChanges: true,
            relations: ['equipmentChanges'],
            cancelToken: new axios.CancelToken(c => {
                this.cancelRequest = c;
            }),
            mapData: data => pick(data, 'id', 'equipment_changes')
        }).then(() => {
            this.cancelRequest = undefined;
            activity.equipmentChanges.forEach(ec => {
                // Move generated ids by backend back to original store.
                ec.id = activityForEquipmentChanges.equipmentChanges.find(ecc =>
                    ecc.equipmentType.id === ec.equipmentType.id &&
                    ecc.isCorrection === ec.isCorrection
                ).id;

                ec.unmarkChanged('load', 'unload');
            });
        })).catch((e) => {
            if (e.valErrors) {
                activity.parseValidationErrors(e.valErrors);
            }
        });
    }

    saveDebounced = debounce(this.save, ACTION_DELAY);

    render() {
        const { allocation, activity, trackingTarget, previousActivityStore, disabled } = this.props;
        const title = t(`contract.field.tracked${upperFirst(trackingTarget)}Equipment.label`);
        const trackedEquipments = allocation.contract.trackedEquipments.filter(te => te.trackingTarget === trackingTarget);

        if (trackedEquipments.length === 0) {
            return null;
        }

        if (previousActivityStore.isLoading) {
            return null;
        }

        return (
            <React.Fragment>
                <SuperText>{title}</SuperText>
                <Table compact definition style={{ marginTop: '0px', marginBottom: '3em' }}>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>
                                {activity.isLoading && this.cancelRequest && <Icon data-test-auto-saving fitted color={theme.primaryColor} name="save" />}
                            </Table.HeaderCell>
                            <Table.HeaderCell>Before</Table.HeaderCell>
                            <Table.HeaderCell>Drop</Table.HeaderCell>
                            <Table.HeaderCell>Take</Table.HeaderCell>
                            <Table.HeaderCell>After</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {trackedEquipments.map(trackedEquipment => {
                            const previousCounts = previousActivityStore
                                ? previousActivityStore.models.reduce((res, act, index) => {
                                    let drop = 0;
                                    let take = 0;

                                    const x = act.trackEquipmentCounts(trackedEquipment, allocation);

                                    if (
                                        x.equipmentChange !== undefined && x.equipmentChange.status !== STATUS_APPLIED && (
                                            (trackedEquipment.trackingTarget === 'allocation') ||
                                            (trackedEquipment.trackingTarget === 'trailer' && activity.trailer.id === act.trailer.id)
                                        )
                                    ) {
                                        drop += x.drop;
                                        take += x.take;
                                    }

                                    return { drop: res.drop + drop, take: res.take + take };
                                }, ({ drop: 0, take: 0 }) )
                                : { drop: 0, take: 0 };


                            let { equipmentChange, name, before, after } = activity.trackEquipmentCounts(trackedEquipment, allocation);

                            if (equipmentChange === undefined) {
                                return null;
                            }

                            if (previousActivityStore && previousActivityStore.length > 0) {
                                before = before - previousCounts.drop + previousCounts.take;
                                after = after - previousCounts.drop + previousCounts.take;
                            }

                            if (activity.type === 'trailer change' && trackedEquipment.trackingTarget === 'trailer') {
                                after = (
                                    <Popup
                                        trigger={<TrailerChangeDropAllSpan>{after} → 0</TrailerChangeDropAllSpan>}
                                        content={t('equipmentChange.dropAllAfterTrailerChange')}
                                    />
                                )
                            }

                            return (
                                <Table.Row data-test-equipment-type-name={name}>
                                    <Table.Cell>{name}</Table.Cell>
                                    <TableCell data-test-before>
                                        {before}
                                        {/*
                                            Disabled correction functionality: https://phabricator.codeyellow.nl/T19354#469854

                                            <StyledInput
                                                name="start"
                                                value={startQuantity}
                                                onChange={(e, { value }) => {
                                                    const parsed = parseInt(value);
                                                    const diff = startTrailerEquipmentQuantity - parsed;

                                                    if (diff > 0) {
                                                        equipmentChangeCorrection.setInput('unload', parsed);
                                                        equipmentChangeCorrection.setInput('load', 0);
                                                    } else {
                                                        equipmentChangeCorrection.setInput('unload', 0);
                                                        equipmentChangeCorrection.setInput('load', parsed);
                                                    }
                                                }}
                                            />
                                        */}
                                    </TableCell>
                                    <TableCell>
                                        <StyledFormNumberInput label data-test-load-equipment-p
                                            disabled={disabled}
                                            model={equipmentChange}
                                            name="unload"
                                            toModel={val => parseInt(val)}
                                            afterChange={() => this.autosave()}
                                            onBlur={() => equipmentChange.hasUserChanges && this.autosave(true)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <StyledFormNumberInput label data-test-load-equipment-s
                                            disabled={disabled}
                                            model={equipmentChange}
                                            name="load"
                                            toModel={val => parseInt(val)}
                                            afterChange={() => this.autosave()}
                                            onBlur={() => equipmentChange.hasUserChanges && this.autosave(true)}
                                        />
                                    </TableCell>
                                    <TableCell data-test-end-quantity>{after}</TableCell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </React.Fragment>
        );
    }
}
