import { TargetMoneyInput  } from '@code-yellow/spider';
import { observer } from 'mobx-react';

@observer
export default class CustomTargetMoneyInput extends TargetMoneyInput {
    static defaultProps = {
        ...TargetMoneyInput.defaultProps,
        placeholder: '0,00',
        prefix: '',
        toFixed: true,
        autoComplete: 'off',
    };
}
