import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import IndicentEdit from 'container/Incident/Edit';
import { Incident } from 'store/Incident';
import { afterSave  } from '@code-yellow/spider';
import { currentUser } from 'helpers/permission';
import { User } from 'store/User';
import moment from 'moment';

@currentUser
@observer
export default class ScreenClaimIncidentEdit extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
    };

    @observable incident = new Incident({
        id: this.props.match.params.id ? parseInt(this.props.match.params.id) : null,
    }, {
        relations: [
            'activity.assignment.truck.entity',
            'activity.assignment.driver1',
            'activity.assignment.driver2',
            'activity.location',
            'followupActionTaker',
            'createdBy',
            'claims.actions',
            'claims.documents',
            'claims.documents.entity',
            'documents',
            'entity',
            'location',
        ],
    });

    componentDidMount() {
        const { currentUser } = this.props;

        if (!this.incident.isNew) {
            this.incident.fetch();
        } else {
            this.incident.parse({
                entity: currentUser.entities.length > 0 ? currentUser.entities.at(0).toJS() : null,
                createdBy: this.props.currentUser.toJS(),
                createdAt: moment(),
            });
        }
    }

    render() {
        return (
            <IndicentEdit
                viewStore={this.props.viewStore}
                incident={this.incident}
                afterSave={afterSave(this.props.history, this.props.viewStore, this.incident)}
            />
        );
    }
}
