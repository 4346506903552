import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { Driver } from './Driver';
import { User } from './User';
import { storeWithHistory } from './History';
import { showSaveNotification, showErrorNotification } from '@code-yellow/spider';
import { get } from 'lodash';
import moment from 'moment-timezone';


export class Wage extends Model {
    static backendResourceName = 'wage';

    @observable id = null;
    @observable periodStart = null;
    @observable periodEnd = null;
    @observable approvedAt = null;
    @observable approvedByPayrollAt = null;
    @observable remark = '';
    @observable recalculation = false;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable deleted = false;

    approveAllConfirmedByDCMWages() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}approve_all_confirmed_by_dcm_wages/`)
                .then(showSaveNotification)
                .catch(e => {
                    if (get(e, 'response.data.errors')) {
                        this.parseValidationErrors(e.response.data.errors);
                    }
                    throw e;
                }
            )
        );
    }

    approveByPayroll(user) {
        if ((user.hasPermission(['asset.approvepayroll_wage:all']))) {
            this.api.post(`${this.url}approve_by_payroll/`)
            .then(()=>this.fetch())
            .then(showSaveNotification);
            return

        }
        showErrorNotification('Only Payroll Administrator can approve Wages')
    }

    unApproveByPayroll() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}un_approve_by_payroll/`)
                .then(()=>this.fetch())
                .then(showSaveNotification)
                .catch(e => {
                    if (get(e, 'response.data.errors')) {
                        this.parseValidationErrors(e.response.data.errors);
                    }
                    throw e;
                }
            )
        );
    }

    approveByDCM(user) {
        if ((user.hasPermission(['asset.approvedcm_wage:all']))) {
            this.setInput('approvedBy', user);
            this.setInput('approvedAt', moment());
            this.save({ onlyChanges: true, relations:['approvedBy'] }).then(showSaveNotification)
            return
        };
        showErrorNotification('Only Care Manager can approve Wages')
    }

    unApproveByDCM() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}un_approve_by_dcm/`)
                .then(() => {
                    this.save({ onlyChanges: true, relations:['approvedBy'] })
                }).then(showSaveNotification)
                .catch(e => {
                    if (get(e, 'response.data.errors')) {
                        this.parseValidationErrors(e.response.data.errors);
                    }
                    throw e;
                }
            )
        );
    }

    casts() {
        return {
            periodStart: Casts.date,
            periodEnd: Casts.date,
            approvedAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            driver: Driver,
            approvedBy: User,
            approvedByPayroll: User,
            createdBy: User,

        };
    }
}

export class WageStore extends Store {
    Model = Wage;
    static backendResourceName = 'wage';
}

export class HistoryWageStore extends storeWithHistory(WageStore){};
