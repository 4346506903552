import React, { Component } from 'react';
import ModalPlanningCustomerSelectDocument from 'screen/ModalPlanningCustomerSelectDocument';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ContactStore } from 'store/Contact';
import { TargetTextInput, TargetSelect, TargetTextArea, FilePreview  } from '@code-yellow/spider';
import { IconButton  } from '@code-yellow/spider'
import TargetWysiwygInput from 'component/TargetWysiwygInput';
import { Icon } from 'semantic-ui-react';
import DroppableButton from 'component/DroppableButton';
import { uniq } from 'lodash';
import styled from 'styled-components'
import { StatusUpdateAttachment } from 'store/StatusUpdateAttachment';
import Dropzone from 'react-dropzone';
import { FormLabel  } from '@code-yellow/spider';



const FilesContainer = styled.div`
    display: flex;
    padding: 5px;
    overflow-x: auto;
    overflow-y: hidden;
    white-spice: nowrap;
`

const StyledFileName = styled.div`
    max-width: 80px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`;

const FileContainer = styled.div`
    position: relative;
    display: inline-block
`

const SingleFileBox = styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 5px;
    border: 0.2px solid #d4d4d5;
    border-radius: 5px;
    background: white;
    clip-path: polygon(0 0,calc(100% - 25px) 0,100% 25px,100% 100%,0 100%);
    cursor: pointer;
`;

const StyledFilePreview = styled(FilePreview)`
    height: 100px;
    width: 100px;
    scale: 0.7;
    margin-top: -18px;
    margin-left: -11px;
`;


const CloseIcon = styled.span`
    color: #767676;
    font-size: 10px;
    pointer-events: none;
`;
const StyledIcon = styled(Icon)`
    scale: 0.8 !important;
    height: 50px !important;
    width: 100px !important;
    margin-left: -11px !important;
    margin-top: 5px !important;
`;
const CornerBox = styled.button`
    position: absolute;
    top: 0;
    right: 5px;
    width: 25px;
    height: 25px;
    background: #d4d4d5;
    display: flex;
    align-items: baseline;
    justify-content: right;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    border-top-right-radius: 5px;
    padding-top: 2px;
    padding-right: 4px;
    font-weight: bold;
    overflow: hidden;
    cursor: pointer;
    border: none;

    &:hover {
        background: #aeaeae;
    }
`;

const activeStyle = {
    opacity: "0.2",
    position: "relative"
};

const MyDropzone = styled(Dropzone)`
    position: relative;
    display: inline;
`

const dragMessageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "black",
    fontSize: "25px",
    fontWeight: "bold",
    pointerEvents: "none",
    textAlign: 'center',
};

const WrappingComponent = ({ children, model, attachments, onDragEnter, onDragLeave }) => (
    attachments ? (
        <MyDropzone
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            activeStyle={activeStyle}
            onDrop={(files) => {
                onDragLeave();
                files.forEach((file) => {
                    const statusUpdateAttachment = new StatusUpdateAttachment({
                        file: file,
                        statusUpdate: model.toJS(),
                    });

                    statusUpdateAttachment
                        .save()
                        .then(() => model.attachments.add(statusUpdateAttachment.toJS()));
                });
            }}
            multiple
            disableClick
        >
            {children}
        </MyDropzone>
    ) : <div>{children}</div>
);

WrappingComponent.propTypes = {
    children: PropTypes.node.isRequired,
    model: PropTypes.object,
    attachments: PropTypes.bool,
    onDragEnter: PropTypes.func,
    onDragLeave: PropTypes.func,
};

@observer
export default class ComposeEmail extends Component {
    static propTypes = {
        wysiwyg: PropTypes.bool,
        viewStore: PropTypes.object.isRequired,
        invoice: PropTypes.object,
        entityInvoice: PropTypes.object,
        model: PropTypes.object.isRequired,
        contactStore: PropTypes.instanceOf(ContactStore).isRequired,
        requiredContactStore: PropTypes.instanceOf(ContactStore),
        className: PropTypes.string,
        errorMessages: PropTypes.array.isRequired,
        regenerate: PropTypes.func,
        attachments: PropTypes.bool,
        activity: PropTypes.object,
    }

    @observable isDragging = false;


    static defaultProps = {
        errorMessages: [],
        attachments: false,
    }

    handleChange = (name, value) => {
        this.props.model.setInput(name, value);
    };

    /**
     * User can add recipients which are not in the contactStore.
     */
    getRecipientOptions = () => {
        const { model, contactStore } = this.props;
        const emails = [];
        const options = [];

        contactStore.models.forEach(contact => {
            emails.push(contact.email);
            options.push(this.formatRecipientOption(contact));
        });

        model.recipients.forEach(email => {
            if (!emails.includes(email)) {
                options.push({
                    value: email,
                    text: email,
                });
            }
        });

        return options;
    }

    formatRecipientOption = contact => {
        let extra = '';

        if (contact.type === 'dayshift') {
            extra = '☀️';
        }

        if (contact.type === 'nightshift') {
            extra = '🌚';
        }

        return {
            value: contact.email,
            text: `${contact.name} ${extra}`,
        };
    };

    openModal = () => {
        this.props.viewStore.setModal({
            render: ModalPlanningCustomerSelectDocument,
            truck: this.props.activity.assignment.truck,
            onDocumentSelected: (d) => {
                const fileToUpload = d.file ? d.file : d.pdfFile;
                const statusUpdateAttachment = new StatusUpdateAttachment({
                    file: fileToUpload,
                    statusUpdate: this.props.model.toJS(),
                });

                statusUpdateAttachment
                    .save()
                    .then(() => this.props.model.attachments.add(statusUpdateAttachment.toJS()));

            },
            onDocumentApprove: () => {},
            type: 'load',
        });
    };

    extractContentType = (url) => {
        const match = url.match(/content_type=([^&]*)/);
        return match ? decodeURIComponent(match[1]) : null;

    }

    extractFilename = (url) => {
        const match = url.match(/filename=([^&]*)/);
        return match ? decodeURIComponent(match[1]) : null;
    }

    renderFilePreview = (previewUrl) => {
        const type = this.extractContentType(previewUrl);
        const title = this.extractFilename(previewUrl)

        if (type.startsWith('image/')) {
            return <StyledFilePreview data-test-attachment type={type} url={previewUrl} title={title}/>;
        } else if (type === 'application/pdf') {
            return (
                <div>
                    <StyledIcon data-test-attachment size='huge' name='pdf file outline' title={title}/>
                    <StyledFileName title={title}>{title}</StyledFileName>
                </div>
            );
        } else if (
            type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                type === 'application/vnd.ms-excel' ||
                type === 'application/vnd.ms-excel.sheet.macroEnabled.12' ||
                type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' ||
                type === 'application/vnd.ms-excel.template.macroEnabled.12'
        ) {
            return (
                <div>
                    <StyledIcon data-test-attachment size='huge' name='file excel outline' title={title}/>
                    <StyledFileName title={title}>{title}</StyledFileName>
                </div>
            );
        } else {
            return (
                <div>
                    <StyledIcon data-test-attachment size='huge' name='file' title={title}/>;
                    <StyledFileName title={title}>{title}</StyledFileName>
                </div>
            )
        }
    };

    renderAttachments = () => {
        if (!this.props.attachments) {
            return null;
        }

        return (
            <div
            >
                <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',  }}
                >
                    <FormLabel style={{fontWeight: 'bold', fontSize: '0.92857143em'}}>Attachments</FormLabel>
                    <div>
                        <IconButton
                            name="search"
                            data-test-show-document-modal-attachments
                            onClick={this.openModal}
                            title={t('activity.field.requiredDocuments.searchChatModal')}
                            style={{ marginRight: '10px' }}
                        />
                        <DroppableButton
                            data-test-upload-document-modal-attachments
                            onDrop={(files) => {
                                files.forEach((file) => {
                                    const statusUpdateAttachment = new StatusUpdateAttachment({
                                        file: file,
                                        statusUpdate: this.props.model.toJS(),
                                    })
                                    statusUpdateAttachment.save().then(() => this.props.model.attachments.add(statusUpdateAttachment.toJS()));
                                })
                            }}
                            accept={'image/jpeg, image/png, application/pdf'}
                            title={t('activity.field.requiredDocuments.upload')}
                        />
                    </div>
                </div>
                <FilesContainer >
                    {this.props.model.attachments.map(attachment => {
                        const file = attachment.file;
                        const previewUrl = file;
                        return (
                            <FileContainer>
                                <SingleFileBox
                                    key={attachment.id}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.open(previewUrl, '_blank')
                                    }}
                                >
                                    {this.renderFilePreview(previewUrl)}
                                </SingleFileBox>
                                <CornerBox
                                    data-test-attachment-remove
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        attachment.delete()
                                    }}
                                >
                                <CloseIcon>x</CloseIcon>
                                </CornerBox>
                            </FileContainer>
                        );
                    })}
                </FilesContainer>
            </div>
        );
}

    render() {
        const { model, className, errorMessages, wysiwyg, requiredContactStore, attachments } = this.props;
        const InputComponent = wysiwyg ? TargetWysiwygInput : TargetTextArea;
        const requiredValues = requiredContactStore ? requiredContactStore.map(this.formatRecipientOption).map(c => c.value) : [];

        return (
            <WrappingComponent
                attachments={attachments}
                model={model}
                isDragging={this.isDragging}
                onDragEnter={() => this.isDragging = true}
                onDragLeave={() => this.isDragging = false}
            >
                <div className={className}>
                    <TargetSelect allowAdditions clearable multiple search
                        label={t('statusUpdate.field.recipients.label')} // Model can be multiple models, even a fake one without translation (like used in invoice).
                        target={model}
                        name="recipients"
                        options={this.getRecipientOptions()}
                        toOption={value => uniq([...requiredValues, ...value])}
                    />
                    {/* The subject cannot contain html. */}
                    <TargetTextInput
                        label={t('statusUpdate.field.subject.label')} // Model can be multiple models, even a fake one without translation (like used in invoice).
                        target={model}
                        name="subject"
                    />
                    {this.renderAttachments()}
                    <InputComponent autoHeight
                        label={t('statusUpdate.field.content.label')}
                        target={model}
                        name="content"
                        mapErrors={errors => [...errors, ...errorMessages]}
                    />
                </div>
                {this.isDragging && (
                    <div style={dragMessageStyle}>
                        {t('driverCertification.documents.drop')}
                    </div>
                )}
            </WrappingComponent>
        );
    }
}
