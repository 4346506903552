import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form, Header } from 'semantic-ui-react';
import { Body, ContentContainer, Content, Toolbar, Link } from 're-cy-cle';
import { RightDivider  } from '@code-yellow/spider';
import { EntityStore } from 'store/Entity';
import { RoadCost } from 'store/RoadCost';
import { CostSupplierStore } from 'store/CostSupplier';
import { CostProductStore } from 'store/CostProduct';
import { TruckStore } from 'store/Truck';
import { DriverStore } from 'store/Driver';
import { LedgerCostStore } from 'store/LedgerCost';
import { LedgerVatStore } from 'store/LedgerVat';
import { FormLabel, ErrorLabel, TargetSelect, TargetRadioButtons, TargetTextInput  } from '@code-yellow/spider';
import TargetMoneyInput from 'component/TargetMoneyInput';
import TargetCurrencySelect from 'component/TargetCurrencySelect';
import { showSaveNotification } from '@code-yellow/spider';
import { EditButton, CancelButton, SaveButton, IconButton  } from '@code-yellow/spider';
import { Modal, Popup, Loader } from 'semantic-ui-react';
import { fetchLocationByTruck } from 'store/Location';
import LocationEditFreeForm from 'container/LocationEditFreeForm';
import DatetimePicker from 'component/form/DatetimePicker';
import { showNotification } from '@code-yellow/spider';
import Activity, { Container, BACKGROUND_COLOR } from 'component/Activity';
import { IconSide, ContentSide } from 'component/Compact/ActivityBlock';
import styled from 'styled-components';
import ModalActivityFinalize from 'screen/ModalActivityFinalize';
import { getViewStore } from 'helpers/viewStore';
import DeclarationSeparatedDraw from 'container/Declaration/DeclarationSeparatedDraw';
import { AssignmentStore } from 'store/Assignment';
import { SERVER_DATETIME_FORMAT } from 'helpers';
import moment from 'moment';
import { User } from 'store/User';
import { Declaration } from 'store/Declaration';

const StyledContainer = styled(Container)`
    justify-content: center;
    align-items: center;
`;

export const REQUIRED_RELATIONS = [
    'entity',
    'costInvoice',
    'costSupplier',
    'costProduct',
    'truck.entity',
    'driver',
    'ledgerCost',
    'ledgerCost.codeReports',
    'ledgerVat',
    'location',
    'activity.location',
    'declaration',

    'declaration.costProduct',
    'declaration.driver',
    'declaration.roadCost.truck.entity',
    'declaration.fuelCost.truck.entity',
    'declaration.roadCost.entity',
    'declaration.fuelCost.entity',
    'declaration.approvedBy',
    'declaration.rejectedBy',
    'declaration.fuelCost.truck',
    'declaration.roadCost.truck',
    'declaration.fuelCost.costProduct',
    'declaration.roadCost.costProduct',
    'declaration.rejectionReason',
    'declaration.roadCost.ledgerCost.codeReports',
    'declaration.fuelCost.ledgerCost.codeReports',
    'declaration.roadCost.activity.location',
    'declaration.roadCost.driver',
    'declaration.roadCost.location',
    'declaration.fuelCost.activity.location',
    'declaration.fuelCost.driver',
    'declaration.fuelCost.location',
    'declaration.roadCost.costInvoice',
    'declaration.fuelCost.costInvoice',
    'declaration.documents',
    'declaration.simpledTransaction',
];

@observer
export default class RoadCostEdit extends Component {
    static propTypes = {
        roadCost: PropTypes.instanceOf(RoadCost).isRequired,
        afterSave: PropTypes.func.isRequired,
        withDeclaration: PropTypes.bool.isRequired,
        disableDeclarationForCostInvoice: PropTypes.bool.isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
        declaration: PropTypes.instanceOf(Declaration).isRequired,

        /**
         * Hide entity field. Useful when entity is given, and not needed
         * to fill in.
         */
        showEntity: PropTypes.bool.isRequired,

        /**
         * Hide supplier field. Useful when supplier is given, and not needed
         * to fill in.
         */
        showSupplier: PropTypes.bool.isRequired,

        /**
         * Disable nettOc / nettFc input fields. Also let net follow gross.
         */
        disableNet: PropTypes.bool.isRequired,

        /**
         * T25643: ledger vat calculation depends on entity and cost supplier.
         * In modal vue there is neither entity nor cost supplier.
         */
        getLedgerVat: PropTypes.bool.isRequired,

        /**
         * T25861
         */
        showHaveReceipt: PropTypes.bool.isRequired,
    };

    // Defaults should match how an edit without a cost invoice / non modal
    // should render.
    static defaultProps = {
        showEntity: true,
        showSupplier: false,
        disableNet: true,
        getLedgerVat: false,
        showHaveReceipt: true,
        disableDeclarationForCostInvoice: false,
    };

    @observable entityStore = new EntityStore({ params: { order_by: 'name' } });
    @observable costProductStore = new CostProductStore({
        relations: ['entities'],
        params: {
            '.cost_type': 'road',
            order_by: 'description',
        }
    });
    @observable costSupplierStore = new CostSupplierStore({ params: { order_by: 'name' } });
    @observable driverStore = new DriverStore({ params: { order_by: 'name' } });
    @observable truckStore = new TruckStore({
        relations: ['entity'],
        params: { order_by: 'license_plate' },
        limit: null,
    });
    @observable ledgerCostStore = new LedgerCostStore({
        relations: ['entity', 'costProduct', 'codeReports', 'currentCodeReport'],
        params: { order_by: 'ledger_code_accounting' },
        limit: null,
    });
    @observable ledgerVatStore = new LedgerVatStore({
        relations: ['entity'],
        params: { order_by: 'ledger_code' },
        limit: null,
    });
    @observable fetchingActivity = false;

    @observable isFirstDriver = true;
    @observable updateTruck = true;

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    save() {
        const { roadCost, afterSave } = this.props;

        const isNew = roadCost.isNew;

        if (roadCost.declaration.id && !roadCost.isNew){
            roadCost.declaration.save()
        }

        return roadCost
            .save({ onlyChanges: true })
            .then(showSaveNotification)
            .then(afterSave)
            .then(()=>{
                if(isNew){
                    showNotification({
                        type: 'info',
                        dismissAfter: 4000,
                        message: t('roadCost.previousId', { id: roadCost.id }),
                    });
                }
            })
    };

    saveNew(roadCost) {
        return this.save()
                .then(() => roadCost.clear())
    }

    saveAndFinalize(roadCost) {
        if (roadCost.isNew) {
            return this.save()
                .then(() => roadCost.finalize())
                .then(() => roadCost.clear());
        } else {
            return this.save()
                .then(() => roadCost.finalize())
        }
    }

    @observable updateId = null;

    componentDidUpdate() {
        const { roadCost, withDeclaration, declaration } = this.props;

        if (withDeclaration && this.updateId !== declaration.id) {
            if ((roadCost.activity.deleted || !roadCost.activity.id) && withDeclaration) {
                this.findLocation()
            }

            if (!roadCost.ledgerCost.id) {
                this.findLedgerCost();
            }

            if (roadCost && roadCost.costProduct) {
                if (roadCost.costProduct.assignActivity && !roadCost.id) {
                    roadCost.setInput('unassignable', false)
                } else if (!roadCost.costProduct.assignActivity && !roadCost.id) {
                    roadCost.setInput('unassignable', true)
                }
            }
            this.updateId = declaration.id
        }
    }

    componentDidMount() {
        const { roadCost, withDeclaration, declaration } = this.props;

        if (withDeclaration) {
            this.updateId = declaration.id
        }

        if ((roadCost.activity.deleted || !roadCost.activity.id) && withDeclaration) {
            this.findLocation()
        }

        if (!roadCost.ledgerCost.id) {
            this.findLedgerCost();
        }

        if (roadCost && roadCost.costProduct) {
            if (roadCost.costProduct.assignActivity && !roadCost.id) {
                roadCost.setInput('unassignable', false)
            } else if (!roadCost.costProduct.assignActivity && !roadCost.id) {
                roadCost.setInput('unassignable', true)
            }
        }
        if (roadCost.unassignable && roadCost.truck) {
            roadCost.truck.clear();
        }
    }

    findLocation = () => {
        const { roadCost, withDeclaration, declaration } = this.props;
        if (withDeclaration && !roadCost.driver.id) {
            roadCost.setInput('driver', declaration.driver)
        }

        this.fetchingActivity = true;

        if (roadCost.location) {
            roadCost.location.clear();
        }

        let assignment = null;

        const assignment_as_driver1 = new AssignmentStore({
            relations: ['truck.entity', 'driver1'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver1': roadCost.driver ? roadCost.driver.id : null,
                '.activated_at:lte': roadCost.transactionDatetime ? roadCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.finished_at:gte': roadCost.transactionDatetime ? roadCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
            }
        })

        const assignment_as_driver2 = new AssignmentStore({
            relations: ['truck.entity', 'driver2'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver2': roadCost.driver ? roadCost.driver.id : null,
                '.activated_at:lte': roadCost.transactionDatetime ? roadCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.finished_at:gte': roadCost.transactionDatetime ? roadCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
            }
        })

        const assignment_as_driver1_without_finished = new AssignmentStore({
            relations: ['truck.entity', 'driver1'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver1': roadCost.driver ? roadCost.driver.id : null,
                '.activated_at:lte': roadCost.transactionDatetime ? roadCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.finished_at:isnull': true,
            }
        })

        const assignment_as_driver2_without_finished = new AssignmentStore({
            relations: ['truck.entity', 'driver2'],
            limit: 1,
            params: {
                order_by: 'activated_at',
                '.driver2': roadCost.driver ? roadCost.driver.id : null,
                '.activated_at:lte': roadCost.transactionDatetime ? roadCost.transactionDatetime.format(SERVER_DATETIME_FORMAT) : moment().format(SERVER_DATETIME_FORMAT),
                '.finished_at:isnull': true,
            }
        })

        Promise.all([
            assignment_as_driver1.fetch(),
            assignment_as_driver2.fetch(),
            assignment_as_driver1_without_finished.fetch(),
            assignment_as_driver2_without_finished.fetch(),
        ])
        .then(()=>{
            if (assignment_as_driver1.length === 1) {
                this.isFirstDriver = true;
                assignment = assignment_as_driver1.at(0)
            } else if (assignment_as_driver2.length === 1){
                this.isFirstDriver = false;
                assignment = assignment_as_driver2.at(0)
            } else if (assignment_as_driver1_without_finished.length === 1){
                this.isFirstDriver = true;
                assignment = assignment_as_driver1_without_finished.at(0)
            } else if (assignment_as_driver2_without_finished.length === 1){
                this.isFirstDriver = false;
                assignment = assignment_as_driver2_without_finished.at(0)
            }

            if (assignment) {
                if (assignment.truck.id && this.props.withDeclaration){
                    if (!roadCost.unassignable && this.updateTruck && !declaration.truck.id) {
                        this.updateTruck = false;
                        roadCost.setInput('truck', assignment.truck);

                    } else if (roadCost.unassignable && this.updateTruck && !declaration.truck.id) {
                        this.updateTruck = false;
                        roadCost.setInput('truck', assignment.truck);
                    } else if (declaration.truck.id && this.updateTruck) {
                        this.updateTruck = false;
                        roadCost.setInput('truck', declaration.truck)
                    }
                    roadCost.setInput('entity', assignment.truck.entity);
                    this.findLedgerCost();
                }
            }
            fetchLocationByTruck(roadCost.truck.id, roadCost.transactionDatetime, false, this.props.withDeclaration, roadCost.driver, this.isFirstDriver)
            .then(({ activity, location }) => {
                if (activity) {
                    roadCost.setInput('activity', activity);
                    if (!this.props.withDeclaration){
                        roadCost.setInput('driver', activity.assignment.driver1);
                    }
                }

                if (location) {
                    roadCost.setInput('location', location);
                }
            })
            .catch(() => {}).then(() => this.fetchingActivity = false);
        })


    }

    /**
     * Ledger Cost can be automatically attached based on entity and truck
     * category.
     */
    findLedgerCost = () => {
        const { roadCost } = this.props;
        this.ledgerCostStore.fetch()
        .then( ()=> {
            roadCost.setLedgerCost(this.ledgerCostStore);
        })
    }

    /**
     * Ledger Cost can be automatically attached based on entity and country.
     */
    findLedgerVat = () => {
        const { roadCost } = this.props;

        // T25630: Ledger VAT can be linked to transaction only if transaction is linked to the Invoice.
        if(roadCost.costInvoice.id){
            this.ledgerVatStore.fetch()
            .then( ()=> {
                roadCost.setLedgerVat(this.ledgerVatStore);
            })
        }
    }

    afterChangeFc = () => {
        const { roadCost } = this.props;

        if (roadCost.isOwnCurrency) {
            roadCost.syncOc();
        }

        roadCost.recalculateVat();
    }

    afterChangeGross = () => {
        const { roadCost, disableNet } = this.props;

        if (disableNet) {
            roadCost.syncNet();
        }

        roadCost.recalculateVat();
    }

    afterChangeCostSupplier = () => {
        const { roadCost } = this.props;

        roadCost.ledgerCost.clear();

        if(!roadCost.id){
            roadCost.costProduct.clear();
        }
        this.costProductStore.clear();
        this.costProductStore.params['.cost_product_supplier_codes.cost_supplier'] = roadCost.costSupplier.id;
        this.costProductStore.fetch();
    }

    renderForm() {
        const { roadCost, showEntity, showSupplier, disableNet, showHaveReceipt } = this.props;

        return (
            <Form>
                {/* <Form.Group widths="equal"> */}
                    {/* <TargetSelect remote fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="ledgerCost" */}
                    {/*     store={this.ledgerCostStore} */}
                    {/*     toOption={ledgerCost => ({ */}
                    {/*         text: `${ledgerCost.ledgerCodeAccounting} - ${ledgerCost.ledgerCodeReporting} - ${ledgerCost.assetTruckCategory}`, */}
                    {/*         value: ledgerCost.id, */}
                    {/*     })} */}
                    {/* /> */}
                {/* </Form.Group> */}
                <Form.Group widths="equal">
                    {showEntity && (
                        <TargetSelect remote fluid
                            target={roadCost}
                            name="entity"
                            store={this.entityStore}
                            toOption={entity => ({
                                text: entity.name,
                                value: entity.id,
                            })}
                            disabled={!roadCost.unassignable}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {showSupplier && (
                        <TargetSelect remote fluid
                            target={roadCost}
                            name="costSupplier"
                            store={this.costSupplierStore}
                            toOption={costSupplier => ({
                                text: costSupplier.name,
                                value: costSupplier.id,
                            })}
                            afterChange={() => {
                                this.findLedgerVat();
                            }}
                        />
                    )}
                    <TargetSelect remote fluid
                        target={roadCost}
                        name="costProduct"
                        store={this.costProductStore}
                        toOption={costProduct => ({text: costProduct.description, value: costProduct.id})}
                        optionFilter={option => {
                            const cp = this.costProductStore.get(option.value);
                            if (!cp) {
                                return false;
                            }
                            if (cp.entities.length === 0) {
                                return true;
                            };

                            const driverEntity = roadCost.driver?.entity?.id

                            if (cp.entities.map(e => e.id).includes(driverEntity)) {
                                return true;
                            }

                            return false;
                        }}

                        afterChange={() => {
                            this.findLedgerCost();
                            if (this.props.withDeclaration) {
                                this.props.declaration.setInput('costProduct', roadCost.costProduct);
                            }
                        }}
                    />
                    <TargetTextInput
                        target={roadCost}
                        name="originalProductDescription"
                        placeholder={t('roadCost.field.originalProductDescription.placeholder')}
                    />
                    {/* <TargetSelect remote fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="ledgerVat" */}
                    {/*     store={this.ledgerVatStore} */}
                    {/*     toOption={ledgerVat => ({ */}
                    {/*         text: `${ledgerVat.ledgerCode} - ${ledgerVat.country}`, */}
                    {/*         value: ledgerVat.id, */}
                    {/*     })} */}
                    {/* /> */}
                    {/* <TargetNumberInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="transactionId" */}
                    {/* /> */}
                    <TargetCurrencySelect fluid
                        target={roadCost}
                        name="fcCode"
                    />
                    <TargetRadioButtons
                        target={roadCost}
                        name="unassignable"
                        options={[
                            { value: false, text: t('form.no') },
                            { value: true, text: t('form.yes') },
                        ]}
                        afterChange={() => {
                            roadCost.truck.clear();
                            roadCost.ledgerCost.clear();
                            this.findLedgerCost();
                        }}
                    />
                    {(showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-have-receipt
                            target={roadCost}
                            name="haveReceipt"
                            options={[
                                { value: false, text: t('form.no') },
                                { value: true, text: t('form.yes') },
                            ]}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {(!showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-paid-by
                            target={roadCost}
                            name="paidBy"
                            options={[
                                { value: 'company', text: t('costSupplier.field.paidBy.company') },
                                { value: 'driver', text: t('costSupplier.field.paidBy.driver') },
                            ]}
                        />
                    )}
                </Form.Group>
                <Form.Group widths="equal">
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalProductDescription" */}
                    {/* /> */}
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalCardNumber" */}
                    {/* /> */}
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetSelect remote search fluid
                        target={roadCost}
                        name="truck"
                        store={this.truckStore}
                        toOption={truck => ({
                            value: truck.id,
                            text: truck.licensePlate
                        })}
                        afterChange={() => {
                            roadCost.entity = roadCost.truck.entity;
                            this.findLocation();
                            this.findLedgerCost();
                        }}
                        disabled={(roadCost.unassignable || this.props.withDeclaration) ? true : false}
                    />
                    {/* <TargetSelect remote fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="trailer" */}
                    {/*     store={this.trailerStore} */}
                    {/*     toOption={trailer => ({ */}
                    {/*         value: trailer.id, */}
                    {/*         text: trailer.licensePlate */}
                    {/*     })} */}
                    {/* /> */}
                    <TargetSelect remote fluid
                        target={roadCost}
                        name="driver"
                        store={this.driverStore}
                        toOption={driver => ({
                            value: driver.id,
                            text: driver.name
                        })}
                        disabled={this.props.withDeclaration}
                    />
                    <DatetimePicker fluid required
                        model={roadCost}
                        name="transactionDatetime"
                        afterChange={this.findLocation}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>
                        <FormLabel required>{t('incident.field.activity.label')}</FormLabel>
                        {roadCost.activity.isNew
                            ? (
                                <React.Fragment>
                                    {/* {copy-paste-empty-activity} */}
                                    <StyledContainer data-test-empty-activity warning>
                                        <IconSide>
                                            ?
                                        </IconSide>
                                        <ContentSide>
                                            <Loader disabled={!this.fetchingActivity} inline="centered" />
                                        </ContentSide>
                                    </StyledContainer>
                                    {roadCost.__backendValidationErrors.activity && roadCost.__backendValidationErrors.activity.length > 0 && (
                                        <ErrorLabel>
                                            {roadCost.__backendValidationErrors.activity.map(error => (
                                                <div>{error}</div>
                                            ))}
                                        </ErrorLabel>
                                    )}
                                </React.Fragment>
                            ) : (
                                <ModalActivityFinalize
                                    activityId={roadCost.activity.id}
                                    viewStore={getViewStore()}
                                    trigger={props => (
                                        <Activity
                                            activity={roadCost.activity}
                                            backgroundColor={BACKGROUND_COLOR}
                                            {...props}
                                        />
                                    )}
                                />
                            )
                        }
                    </Form.Field>
                    <Form.Field width={13}>
                        <LocationEditFreeForm
                            onChange={(location) => {
                                // Prevent the old location from being used if the location is cleared
                                if (location.address === '') {
                                    location = null;
                                }
                                // Manually input address
                                roadCost.setInput('location', location);

                                this.findLedgerVat();
                            }}
                            changeOnEmpty={true}
                            label={t('activity.field.location.label')}
                            location={roadCost.location}
                            error={roadCost.backendValidationErrors.location}
                        />
                    </Form.Field>
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalAddress" */}
                    {/* /> */}
                    {/* <TargetTextInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="originalCountryCode" */}
                    {/* /> */}
                    {/* <TargetNumberInput fluid */}
                    {/*     target={roadCost} */}
                    {/*     name="mautKm" */}
                    {/* /> */}
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid
                        target={roadCost}
                        name="netFc"
                        disabled={disableNet}
                        afterChange={()=> {
                            this.afterChangeFc();
                            }
                        }
                    />
                    <TargetMoneyInput fluid
                        target={roadCost}
                        name="grossFc"
                        afterChange={() => {
                            this.afterChangeFc();
                            this.afterChangeGross();
                        }}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={roadCost}
                        name="vatFc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={roadCost}
                        name="ledgerVat"
                        store={this.ledgerVatStore}
                        toOption={lv => ({
                            value: lv.id,
                            text: lv.getLedgerVatText(),
                        })}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid
                        target={roadCost}
                        name="netOc"
                        disabled={roadCost.isOwnCurrency || disableNet}
                        afterChange={() => {
                            roadCost.recalculateVat()
                            }
                        }
                    />
                    <TargetMoneyInput fluid
                        target={roadCost}
                        name="grossOc"
                        disabled={roadCost.isOwnCurrency}
                        afterChange={() => {
                            this.afterChangeGross()
                            }
                        }
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={roadCost}
                        name="vatOc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={roadCost}
                        name="ledgerCost"
                        store={this.ledgerCostStore}
                        toOption={lc => ({
                            value: lc.id,
                            text: lc.getLedgerCostText(roadCost.haveReceipt, roadCost.transactionDatetime),
                        })}
                    />
                </Form.Group>
                {(roadCost.declaration && roadCost.id && !this.props.disableDeclarationForCostInvoice) && <DeclarationSeparatedDraw isRoadCost={true} declaration={roadCost.declaration}/>}
            </Form>
        );
    }

    //Initially, we used the standard view of road/fuel cost for our declarations. However, over time, we realized that a tailored, individual style needed to be developed.
    renderFormForDeclaration() {
        const { roadCost, showEntity, showSupplier, disableNet, showHaveReceipt } = this.props;

        return (
            <Form>
                <Form.Group widths="equal">
                    {showEntity && (
                        <TargetSelect remote fluid
                            target={roadCost}
                            name="entity"
                            store={this.entityStore}
                            toOption={entity => ({
                                text: entity.name,
                                value: entity.id,
                            })}
                            disabled={!roadCost.unassignable}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {showSupplier && (
                        <TargetSelect remote fluid
                            target={roadCost}
                            name="costSupplier"
                            store={this.costSupplierStore}
                            toOption={costSupplier => ({
                                text: costSupplier.name,
                                value: costSupplier.id,
                            })}
                            afterChange={() => {
                                this.findLedgerVat();
                            }}
                        />
                    )}
                    <TargetSelect remote fluid
                        target={roadCost}
                        name="costProduct"
                        store={this.costProductStore}
                        toOption={costProduct => ({text: costProduct.description, value: costProduct.id})}
                        optionFilter={option => {
                            const cp = this.costProductStore.get(option.value);
                            if (!cp) {
                                return false;
                            }
                            if (cp.entities.length === 0) {
                                return true;
                            };

                            const driverEntity = roadCost.driver?.entity?.id

                            if (cp.entities.map(e => e.id).includes(driverEntity)) {
                                return true;
                            }

                            return false;
                        }}
                        afterChange={() => {
                            this.findLedgerCost();
                            if (roadCost.costProduct.assignActivity && !roadCost.id) {
                                roadCost.setInput('unassignable', false)
                            } else if (!roadCost.costProduct.assignActivity && !roadCost.id) {
                                roadCost.setInput('unassignable', true)
                            }
                            if (this.props.withDeclaration) {
                                this.props.declaration.setInput('costProduct', roadCost.costProduct);
                            }
                        }}
                    />
                    <TargetTextInput
                        target={roadCost}
                        name="originalProductDescription"
                        placeholder={t('roadCost.field.originalProductDescription.placeholder')}
                    />
                    <TargetCurrencySelect fluid
                        target={roadCost}
                        name="fcCode"
                    />
                    <TargetRadioButtons
                        target={roadCost}
                        name="unassignable"
                        options={[
                            { value: false, text: t('form.no') },
                            { value: true, text: t('form.yes') },
                        ]}
                        afterChange={() => {
                            if (roadCost.unassignable) {
                                roadCost.truck.clear();
                                this.findLocation();
                            }

                            roadCost.ledgerCost.clear();
                            this.findLedgerCost();
                        }}
                    />
                    {(showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-have-receipt
                            target={roadCost}
                            name="haveReceipt"
                            options={[
                                { value: false, text: t('form.no') },
                                { value: true, text: t('form.yes') },
                            ]}
                            afterChange={() => {
                                this.findLedgerCost();
                            }}
                        />
                    )}
                    {(!showHaveReceipt || this.props.withDeclaration) && (
                        <TargetRadioButtons required data-test-road-cost-paid-by
                            target={roadCost}
                            name="paidBy"
                            options={[
                                { value: 'company', text: t('costSupplier.field.paidBy.company') },
                                { value: 'driver', text: t('costSupplier.field.paidBy.driver') },
                            ]}
                            // disabled={(!currentUser.hasPermission(['cost.manage_declaration:cash']) || this.props.declaration.creator === 'simpled' || this.props.declaration.creator === 'driver') && this.props.declaration.id}
                        />
                    )}
                </Form.Group>
                <Form.Group widths="equal">
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetSelect remote search fluid
                        target={roadCost}
                        name="truck"
                        store={this.truckStore}
                        toOption={truck => ({
                            value: truck.id,
                            text: truck.licensePlate
                        })}
                        afterChange={() => {
                            roadCost.entity = roadCost.truck.entity;
                            this.findLocation();
                            this.findLedgerCost();
                        }}
                        disabled={(roadCost.unassignable && this.props.withDeclaration) ? true : false}
                    />
                    <TargetTextInput remote fluid
                        target={roadCost.driver}
                        name="dataExternalId"
                        disabled={this.props.withDeclaration}
                    />
                    <TargetSelect remote fluid
                        target={roadCost}
                        name="driver"
                        store={this.driverStore}
                        toOption={driver => ({
                            value: driver.id,
                            text: driver.name
                        })}
                        disabled={this.props.withDeclaration}
                    />
                    <DatetimePicker fluid required
                        model={roadCost}
                        name="transactionDatetime"
                        afterChange={this.findLocation}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field width={3}>
                        <FormLabel required>{t('incident.field.activity.label')}</FormLabel>
                        {roadCost.activity.isNew
                            ? (
                                <React.Fragment>
                                    <StyledContainer data-test-empty-activity warning>
                                        <IconSide>
                                            ?
                                        </IconSide>
                                        <ContentSide>
                                            <Loader disabled={!this.fetchingActivity} inline="centered" />
                                        </ContentSide>
                                    </StyledContainer>
                                    {roadCost.__backendValidationErrors.activity && roadCost.__backendValidationErrors.activity.length > 0 && (
                                        <ErrorLabel>
                                            {roadCost.__backendValidationErrors.activity.map(error => (
                                                <div>{error}</div>
                                            ))}
                                        </ErrorLabel>
                                    )}
                                </React.Fragment>
                            ) : (
                                <ModalActivityFinalize
                                    activityId={roadCost.activity.id}
                                    viewStore={getViewStore()}
                                    trigger={props => (
                                        <Activity
                                            activity={roadCost.activity}
                                            backgroundColor={BACKGROUND_COLOR}
                                            {...props}
                                        />
                                    )}
                                />
                            )
                        }
                    </Form.Field>
                    <Form.Field width={13}>
                        <LocationEditFreeForm
                            onChange={(location) => {
                                // Prevent the old location from being used if the location is cleared
                                if (location.address === '') {
                                    location = null;
                                }
                                // Manually input address
                                roadCost.setInput('location', location);

                                this.findLedgerVat();
                            }}
                            changeOnEmpty={true}
                            label={t('activity.field.location.label')}
                            location={roadCost.location}
                            error={roadCost.backendValidationErrors.location}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid
                        target={roadCost}
                        name="netFc"
                        disabled={disableNet}
                        afterChange={()=> {
                            this.afterChangeFc();
                            }
                        }
                    />
                    <TargetMoneyInput fluid
                        target={roadCost}
                        name="grossFc"
                        afterChange={() => {
                            this.afterChangeFc();
                            this.afterChangeGross();
                            roadCost.recalculateValuesForDeclaration()
                            roadCost.recalculateValuesForDeclarationEurOc()
                            roadCost.recalculateValuesForDeclarationEurFc()
                        }}
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={roadCost}
                        name="vatFc"
                    />
                     <TargetMoneyInput fluid
                        target={roadCost}
                        name="netOc"
                        disabled={roadCost.isOwnCurrency || disableNet}
                        afterChange={() => {
                            roadCost.recalculateVat()
                            }
                        }
                    />
                    <TargetMoneyInput fluid
                        target={roadCost}
                        name="grossOc"
                        disabled={roadCost.isOwnCurrency}
                        afterChange={() => {
                            this.afterChangeGross()
                            roadCost.recalculateValuesForDeclaration()
                            roadCost.recalculateValuesForDeclarationEurOc()
                            roadCost.recalculateValuesForDeclarationEurFc()
                            }
                        }
                    />
                    <TargetMoneyInput fluid disabled allowNegative
                        target={roadCost}
                        name="vatOc"
                    />
                    <TargetSelect fluid disabled remote search
                        target={roadCost}
                        name="ledgerVat"
                        store={this.ledgerVatStore}
                        toOption={lv => ({
                            value: lv.id,
                            text: lv.getLedgerVatText(),
                        })}
                    />
                    <TargetSelect fluid disabled remote search
                        target={roadCost}
                        name="ledgerCost"
                        store={this.ledgerCostStore}
                        toOption={lc => ({
                            value: lc.id,
                            text: lc.getLedgerCostText(roadCost.haveReceipt, roadCost.transactionDatetime),
                        })}
                    />
                </Form.Group>
                {(roadCost.declaration && roadCost.id && !this.props.disableDeclarationForCostInvoice) && <DeclarationSeparatedDraw isRoadCost={true} declaration={roadCost.declaration}/>}
            </Form>
        );
    }


    render() {
        const { roadCost } = this.props;

        return (
            <Body>
                <ContentContainer>
                    <Content>
                        <Header as="h1">
                            {roadCost.id
                                ? t('roadCost.edit.title')
                                : t('roadCost.create.title')}
                            {roadCost.costInvoice.id && (
                                <Link to={`/administration/cost-invoice/${roadCost.costInvoice.id}/edit` } icon >
                                    <IconButton title="View related Cost invoice" name='eye'/>
                                </Link>
                            )}
                        </Header>
                        {this.renderForm()}
                    </Content>
                </ContentContainer>
                <Toolbar>
                    <RightDivider />
                    {!roadCost.rejectedAt ? <SaveButton compact data-test-save-and-finalize-button
                        color={'red'}
                        content={t('costInvoice.edit.rejectButton')}
                        onClick={()=>roadCost.rejectRoadCost()}
                        loading={roadCost.isLoading}
                        disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id) || !roadCost.grossOc || roadCost.finalizedAt}
                    /> :
                    <SaveButton compact data-test-save-and-finalize-button
                        color={'red'}
                        content={t('costInvoice.edit.unrejectButton')}
                        onClick={()=>roadCost.unrejectRoadCost()}
                        loading={roadCost.isLoading}
                        disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id) || !roadCost.grossOc}
                    />
                    }
                    {!!roadCost.finalizedAt && (
                        <SaveButton compact data-test-unfinalize-button
                            color="purple"
                            content={t('form.unfinalizeButton')}
                            onClick={()=>roadCost.unfinalize()}
                            loading={roadCost.isLoading}
                            disabled={roadCost.rejectedAt}
                        />
                    )}
                    <SaveButton primary compact data-test-save-and-finalize-button
                        content={t('costInvoice.edit.saveAndFinalizeButton')}
                        onClick={() => this.saveAndFinalize(roadCost)}
                        loading={roadCost.isLoading}
                        disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id) || roadCost.rejectedAt || !roadCost.grossOc || roadCost.rejectedAt || roadCost.finalizedAt}
                    />

                    <Popup hoverable
                        content={!roadCost.ledgerCost.id? t('roadCost.create.ledgerCostWarning'):((roadCost.location && !roadCost.location.id) && t('roadCost.create.locationWarning'))}
                        trigger={
                            <span data-test-save-button-container>
                                <SaveButton primary compact
                                    onClick={roadCost.isNew ? (() => this.saveNew(roadCost)) : this.save}
                                    loading={roadCost.isLoading}
                                    content={roadCost.id?t('roadCost.create.saveButton'):t('roadCost.create.saveAndNewButton')}
                                    disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id) || roadCost.rejectedAt || !roadCost.grossOc || roadCost.rejectedAt || roadCost.finalizedAt}
                                />
                            </span>}
                    />
                </Toolbar>
            </Body>
        );
    }
}

@observer
export class EditModal extends RoadCostEdit {
    static propTypes = {
        roadCost: PropTypes.instanceOf(RoadCost).isRequired,
        afterSave: PropTypes.func.isRequired,
        trigger: PropTypes.func.isRequired,
        disableDeclarationForCostInvoice: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        ...RoadCostEdit.defaultProps,
        showEntity: false,
        showSupplier: false,
        disableNet: false,
        getLedgerVat: true,
        showHaveReceipt: false,
        trigger(props) {
            return <EditButton {...props} />;
        },
        disableDeclarationForCostInvoice: true,
    }

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    save() {
        const { afterSave } = this.props;
        super.save()
            .then(afterSave)
            .then(this.close);
    }

    saveAndFinalize() {
        const { afterSave, roadCost } = this.props;
        super.save()
            .then(()=>roadCost.finalize())
            .then(afterSave)
            .then(this.close);
    }

    render() {
        const { roadCost, trigger, ...props } = this.props;

        return (
            <Modal {...props}
                centered={false}
                open={this.isOpen}
                onClose={this.close}
                trigger={trigger({ onClick: this.open })}
            >
                <Modal.Content>
                    {this.renderForm()}
                </Modal.Content>
                <Modal.Actions>
                    <CancelButton onClick={this.close} />
                    <RightDivider />
                    <Popup hoverable
                        content={!roadCost.ledgerCost.id? t('roadCost.create.ledgerCostWarning'):((roadCost.location && !roadCost.location.id) && t('roadCost.create.locationWarning'))}
                        trigger={
                            <span data-test-save-button>
                                <SaveButton primary compact
                                    onClick={this.save}
                                    loading={roadCost.isLoading}
                                    disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id) || roadCost.rejectedAt || roadCost.rejectedAt}
                                />
                            </span>}
                    />
                    <SaveButton primary compact data-test-save-and-finalize-button
                        content={t('costInvoice.edit.saveAndFinalizeButton')}
                        onClick={()=>this.saveAndFinalize()}
                        loading={roadCost.isLoading}
                        disabled={!roadCost.ledgerCost.id || (roadCost.location && !roadCost.location.id) || roadCost.rejectedAt}
                    />
                    {!!roadCost.finalizedAt && (
                        <SaveButton compact data-test-unfinalize-button
                            color="purple"
                            content={t('form.unfinalizeButton')}
                            onClick={()=>roadCost.unfinalize()}
                            loading={roadCost.isLoading}
                        />
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}

@observer
export class RoadCostForDeclaration extends RoadCostEdit {
    static propTypes = {
        roadCost: PropTypes.instanceOf(RoadCost).isRequired,
        afterSave: PropTypes.func.isRequired,
        trigger: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...RoadCostEdit.defaultProps,
        showEntity: true,
        showSupplier: false,
        disableNet: false,
        getLedgerVat: true,
        showHaveReceipt: false,
        withDeclaration: true,
        trigger(props) {
            return <EditButton {...props} />;
        },
    }

    constructor(...args) {
        super(...args);
        this.save = this.save.bind(this);
    }

    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    save() {
        const { afterSave } = this.props;
        super.save()
            .then(afterSave)
            .then(this.close);
    }

    saveAndFinalize() {
        const { afterSave, roadCost } = this.props;
        super.save()
            .then(()=>roadCost.finalize())
            .then(afterSave)
            .then(this.close);
    }

    render() {

        return (
            <div>{this.renderFormForDeclaration()}</div>
        );
    }
}
