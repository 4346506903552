import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Grid } from 'semantic-ui-react';
import { FormSelect  } from '@code-yellow/spider';
import { BEPTicket, BEPTicketSubcategoryStore, SUBJECT_TYPE, SUBJECT_TYPE_DRIVER, SUBJECT_TYPE_CUSTOMER, SUBJECT_TYPE_USER, SUBJECT_TYPE_ENTITY, SUBJECT_TYPE_SUPPLIER, SUBJECT_TYPE_SUBCATEGORY } from 'store/BEPTicket';
import { UserStore } from 'store/User';
import { EntityStore } from 'store/Entity';
import { DriverStore } from 'store/Driver';
import { SupplierStore } from 'store/Supplier';
import { CustomerStore } from 'store/Customer';
import RemoteSelect from 'component/RemoteSelect';
import { capitalize } from 'lodash';

/**
 * Render a "subject type" and depending on the selected type, render
 * the related subject type's dropdown.
 */
@observer
export default class Subject extends Component {
    static propTypes = {
        bepTicket: PropTypes.instanceOf(BEPTicket).isRequired,
        subject: PropTypes.string.isRequired,
        allowedTypes: PropTypes.array,
        disabled: PropTypes.bool,
    };

    @observable entityStore = new EntityStore();
    @observable userStore = new UserStore();
    @observable driverStore = new DriverStore();
    @observable customerStore = new CustomerStore();
    @observable supplierStore = new SupplierStore();
    @observable bepTicketSubcategoryStore = new BEPTicketSubcategoryStore();

    clearUnusedSubjects = () => {
        const { bepTicket, subject, allowedTypes } = this.props;
        // I added a filter because it try to call SubjectFromEntity (Przemo)
        SUBJECT_TYPE.filter(t => !allowedTypes || allowedTypes.includes(t)).forEach(subjectType => {
            if (subjectType !== bepTicket[`${subject}Type`]) {
                const type = capitalize(subjectType);

                if (bepTicket[`${subject}${type}`]) {
                    bepTicket[`${subject}${type}`].clear();
                }
                if (subject+type !== 'subjectFromEntity' && subject+type !=='subjectFromSubcategory' ){  //quick fix for not related subjects
                    bepTicket.setInput(`${subject}${type}`, bepTicket[`${subject}${type}`]);
                }
            }
        });
    }

    render() {
        const { bepTicket, subject, allowedTypes, disabled } = this.props;

        return (
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <FormSelect
                            label={t(`bepTicket.field.${subject}.label`)}
                            model={bepTicket}
                            name={`${subject}Type`}
                            disabled={disabled}
                            options={SUBJECT_TYPE.filter(t => !allowedTypes || allowedTypes.includes(t)).map(k => ({
                                text: t(`bepTicket.subjectType.${k}`),
                                value: k,
                            }))}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        {bepTicket[`${subject}Type`] === SUBJECT_TYPE_USER && (
                            <RemoteSelect
                                model={bepTicket}
                                name={`${subject}User`}
                                label={t('bepTicket.field.subjectUser.label')}
                                disabled={disabled}
                                remoteStore={this.userStore}
                                renderOption={user => ({
                                    text: user.fullName,
                                    value: user.id,
                                })}
                                renderCell={user => user.fullName}
                                afterChange={this.clearUnusedSubjects}
                            />
                        )}
                        {bepTicket[`${subject}Type`] === SUBJECT_TYPE_DRIVER && (
                            <RemoteSelect
                                model={bepTicket}
                                name= {`${subject}Driver`}
                                label={t('bepTicket.field.subjectDriver.label')}
                                disabled={disabled}
                                remoteStore={this.driverStore}
                                renderOption={driver => ({
                                    value: driver.id,
                                    text: `${driver.dataExternalId} ${driver.name}`,
                                })}
                                renderCell={driver => `${driver.dataExternalId} ${driver.name}`}
                                afterChange={this.clearUnusedSubjects}
                            />
                        )}
                        {bepTicket[`${subject}Type`] === SUBJECT_TYPE_CUSTOMER && (
                            <RemoteSelect
                                model={bepTicket}
                                name={`${subject}Customer`}
                                label={t('bepTicket.field.subjectCustomer.label')}
                                disabled={disabled}
                                remoteStore={this.customerStore}
                                renderOption={customer => ({
                                    text: customer.name,
                                    value: customer.id,
                                })}
                                renderCell={customer => customer.name}
                                afterChange={this.clearUnusedSubjects}
                            />
                        )}
                        {bepTicket[`${subject}Type`] === SUBJECT_TYPE_ENTITY && (
                            <RemoteSelect
                                model={bepTicket}
                                name={`${subject}Entity`}
                                label={t('bepTicket.field.subjectEntity.label')}
                                disabled={disabled}
                                remoteStore={this.entityStore}
                                renderOption={entity => ({
                                    text: entity.name,
                                    value: entity.id,
                                })}
                                renderCell={entity => entity.name}
                                afterChange={this.clearUnusedSubjects}
                            />
                        )}
                        {bepTicket[`${subject}Type`] === SUBJECT_TYPE_SUPPLIER && (
                            <RemoteSelect
                                model={bepTicket}
                                name={`${subject}Supplier`}
                                label={t('bepTicket.field.subjectSupplier.label')}
                                disabled={disabled}
                                remoteStore={this.supplierStore}
                                renderOption={supplier => ({
                                    text: supplier.name,
                                    value: supplier.id,
                                })}
                                renderCell={supplier => supplier.name}
                                afterChange={this.clearUnusedSubjects}
                            />
                        )}
                        {bepTicket[`${subject}Type`] === SUBJECT_TYPE_SUBCATEGORY && (
                            <RemoteSelect
                                model={bepTicket}
                                name={`${subject}Subcategory`}
                                label={t('bepTicket.field.subjectSubcategory.label')}
                                disabled={disabled}
                                remoteStore={this.bepTicketSubcategoryStore}
                                renderOption={subcat => ({
                                    text: subcat.name,
                                    value: subcat.id,
                                })}
                                renderCell={subcat => subcat.name}
                                afterChange={this.clearUnusedSubjects}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
