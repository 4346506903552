import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Form, Grid, Table, Modal, Icon, Button, Image, Radio, Feed, Popup } from 'semantic-ui-react';
import { TargetTextInput, TargetTextArea  } from '@code-yellow/spider';
import { User } from 'store/User';
import { Declaration, DeclarationStore } from 'store/Declaration';
import { DATETIME_FORMAT, DATE_SHORT_FORMAT } from 'helpers';
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components';
import { RightDivider  } from '@code-yellow/spider';
import { CancelButton, SaveButton, IconButton  } from '@code-yellow/spider';
import { ItemButton } from 'component/AdminOverview';
import DroppableButton from 'component/DroppableButton';
import moment from 'moment';
import { showSaveNotification, showNotification } from '@code-yellow/spider';
import { Document } from 'store/Document';
import ReactCrop from 'react-image-crop';
import { api } from 'store/Base';
import { formatMoney } from 'helpers';
import TargetMoneyInput from 'component/TargetMoneyInput';
import HistoryItem from 'component/History/Item';


export const PrimaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

export const SecondaryWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    min-width: 400px;
    max-height: 150px;
    min-height: 150px;
`;

export const ThertriaryWrapper = styled.div`
    max-width: '5px';
    justify-content: center;
`;

const PicAndMenuContainer = styled.div`
    justify-content: center;
    flex-direction: column;
    display: flex;
    max-height: 150px;
    min-height: 150px;
    width: auto;
`;

const PicAndMenuContainerModal = styled.div`
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    max-height: 600px;
    min-height: 150px;
    width: auto;
`;

export const Wrapper = styled.div`
    display: flex;
`

@observer
export default class DeclarationSeparatedDraw extends Component {
    static propTypes = {
        declaration: PropTypes.instanceOf(Declaration).isRequired,
        currentUser: PropTypes.instanceOf(User).isRequired,
        afterSave: PropTypes.func.isRequired,
        afterDeclarationSave: PropTypes.func.isRequired,
        groupedDeclarationStore: PropTypes.instanceOf(DeclarationStore).isRequired,
    }

    @observable showSystemHistory = false;
    toggleSystemHistory = () => {
        const { declaration } = this.props;
        this.showSystemHistory = !this.showSystemHistory;

        if (this.showSystemHistory) {
            declaration.fetchHistory();
        }
    }

    renderHistory() {
        const { declaration, currentUser } = this.props;

        return (
            <Feed data-test-history>
                {declaration._history.map(commentOrHistory => (
                    <HistoryItem data-test-declaration-history-content
                        currentUser={currentUser}
                        history={commentOrHistory}
                    />
                ))}
            </Feed>
        );
    }

    @observable crop = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };

    @observable pixelCrop = {
        x: 0,
        y: 0,
        width: 10,
        height: 10,
    };
    @observable fullScreen = false;
    @observable isCropping = false;
    @observable selectedDocument = null;

    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => this.isOpen = false;

    declarationStatus = (declaration) => {
        if (this.selectedDocument) {
           if (this.selectedDocument.status === 'approved by customer service') {
            return 'Approved'
           } else if (this.selectedDocument.status === 'rejected') {
            return 'Rejected'
           }
        }
        return 'Pending'
    }

    @observable multiCurrencyRate = null

    renderExchandeDiffPopup = (declaration) => {
        const percentageDiff = (simpledExchange, multiCurrencyRate) => {
            const diff = Math.abs(simpledExchange - multiCurrencyRate);
            const average = (simpledExchange + multiCurrencyRate) / 2;
            const result = (diff / average) * 100;
            return parseFloat(result.toFixed(2));
        }

        let simpledExchange = null;
        let multiCurrencyRate = null;

        if (declaration.simpledTransaction && declaration.simpledTransaction.originalTxnAmount && declaration.simpledTransaction.billingAmount) {
            simpledExchange = declaration.simpledTransaction.originalTxnAmount / declaration.simpledTransaction.billingAmount
        }

        if (this.multiCurrencyRate) {
            multiCurrencyRate = this.multiCurrencyRate.at(0).rate
        }

        if (simpledExchange && multiCurrencyRate) {
            const diff = percentageDiff(parseFloat(simpledExchange.toFixed(3)), parseFloat(multiCurrencyRate))
            const result = () => {
                return (
                    <div>
                        <div>{'Multi-currency and simplified differences'}</div>
                        <div>{`${diff}% | ${parseFloat((simpledExchange * declaration.quantityFc) - (multiCurrencyRate* declaration.quantityFc)).toFixed(2)} Eur`}</div>
                    </div>
                )
            }

            if (diff >= 1) {
                return (<Popup data-test-multicurrency-in-declaration-info
                    content={result()}
                    trigger={<Icon data-test-multicurrency-in-declaration name='exclamation triangle' color='orange'/>}
                    position='top left'
                />)
            } return
        }
    }

    exchangeDiff() {
        const { declaration } = this.props;

        const percentageDiff = (simpledExchange, multiCurrencyRate) => {
            const diff = Math.abs(simpledExchange - multiCurrencyRate);
            const average = (simpledExchange + multiCurrencyRate) / 2;
            const result = (diff / average) * 100;
            return parseFloat(result.toFixed(2));
        }

        let simpledExchange = null;
        let multiCurrencyRate = null;

        if (declaration.simpledTransaction.originalTxnAmount && declaration.simpledTransaction.billingAmount) {
            simpledExchange = declaration.simpledTransaction.originalTxnAmount / declaration.simpledTransaction.billingAmount
        }

        if (this.multiCurrencyRate) {
            multiCurrencyRate = this.multiCurrencyRate.at(0).rate
        }

        if (simpledExchange && multiCurrencyRate) {
            return percentageDiff(parseFloat(simpledExchange.toFixed(3)), parseFloat(multiCurrencyRate))
        }
    }

    componentDidMount() {
        const { declaration } = this.props;
        if (declaration.documentsToDisplay.length !== 0) {
            const notRejectedDocument = declaration.documentsToDisplay.find(document => document.status !== 'rejected')
            if (notRejectedDocument) {
                this.selectedDocument = notRejectedDocument
            }
        }

        const date = declaration.createdAt ? declaration.createdAt : moment()
        declaration.exchangeMoney(date, declaration.currency)
        .then((currencyStore) => {
            this.multiCurrencyRate = currencyStore;
        })
    }

    componentDidUpdate() {
        const { declaration } = this.props;

        if (this.selectedDocument) {
            if (!declaration.documentsToDisplay.find(doc => doc.id === this.selectedDocument.id)) {
                if (declaration.documentsToDisplay.length !== 0) {
                    const notRejectedDocument = declaration.documentsToDisplay.find(document => document.status !== 'rejected')
                    if (notRejectedDocument) {
                        this.selectedDocument = notRejectedDocument
                    }
                }
            }
            if (!declaration.documentsToDisplay.find(doc => doc.id === this.selectedDocument.id) && declaration.documentsToDisplay.length === 0) {
                this.selectedDocument = null;
            }
        }


        if (declaration.documentsToDisplay.length !== 0 && !this.selectedDocument) {
            this.selectedDocument = declaration.documentsToDisplay[0]
        }
    }

    uploadImage = files => {
        const { declaration } = this.props;

        files.forEach((file) =>{
            const doc = new Document({
                type: 'declaration',
                status: 'approved by planner',
                scannedAt: moment(),
                truck: declaration.fuelCost.truck.id ? declaration.fuelCost.truck.toJS() : declaration.roadCost.truck.toJS(),
            }, {
                relations: ['truck'],
            });

            if (file.type === 'application/pdf') {
                doc.setInput('pdfFile', file);
            } else {
                doc.setInput('file', file);
            }

            doc.save().then(() => {
                this.props.declaration.documents.chooseDocument(doc);
                showSaveNotification();
            });
        });
    };

    handleSelect = direction => {
        const { declaration } = this.props;

        if (declaration.documentsToDisplay.length !== 0 && this.selectedDocument) {
            let index = declaration.documentsToDisplay.findIndex(item => item.id === this.selectedDocument.id)

            if (direction === 'right') {
                if (declaration.documentsToDisplay.length === index + 1) {
                    index = 0
                } else {
                    index = index + 1
                }
            } else if (direction === 'left') {
                if (declaration.documentsToDisplay.length === 0) {
                    index = declaration.documentsToDisplay.length
                } else {
                    index = index - 1
                }
            }

            this.selectedDocument = declaration.documentsToDisplay[index]
        }
    };

    renderImage = () => {
        if (this.selectedDocument) {
            if (this.isOpen) {
                return (
                    <React.Fragment>
                        {!this.isCropping
                            ? <PicAndMenuContainerModal><Image centered data-test-selected-document style={{ maxWidth: '550px', maxHeight: '550px', height: 'auto', width: 'auto' }} src={this.selectedDocument.file}/>{this.renderPicChangeMenu()}</PicAndMenuContainerModal>
                            : (
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <ReactCrop
                                        src={this.selectedDocument.file}
                                        crop={this.crop}
                                        onChange={(crop, pixelCrop) => {
                                            this.crop = crop;
                                            this.pixelCrop = pixelCrop;
                                        }}
                                    />
                                </div>
                            )
                        }
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {!this.isCropping
                            ? <PicAndMenuContainer><Image centered data-test-selected-document style={{ maxWidth: '115px', maxHeight: '115px', height: 'auto', width: 'auto' }} src={this.selectedDocument.file} onClick={() => this.open()} />{this.renderPicChangeMenu()}</PicAndMenuContainer>
                            : (
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <ReactCrop
                                        src={this.selectedDocument.file}
                                        crop={this.crop}
                                        onChange={(crop, pixelCrop) => {
                                            this.crop = crop;
                                            this.pixelCrop = pixelCrop;
                                        }}
                                    />
                                </div>
                            )
                        }
                    </React.Fragment>
                );
            }
        }
    }

    handleSharpen = (sharpness) => {
        if (this.selectedDocument) {
            this.selectedDocument.sharpen(sharpness)
            .then(() => this.selectedDocument.fetch())
            .then(showSaveNotification)
            .catch(() => { });
        }
    };

    handleCrop = () => {
        const toggleCrop = () => {
            this.isCropping = !this.isCropping;
        }
        if (this.selectedDocument) {
            if (this.isCropping) {
                return this.selectedDocument.crop(this.pixelCrop)
                    .then(showSaveNotification)
                    .then(toggleCrop);
            }
        }
        toggleCrop();
    }

    handleBrighten = (brightness) => {
        if (this.selectedDocument) {
            this.selectedDocument.brighten(brightness)
                .then(() => this.selectedDocument.fetch())
                .then(showSaveNotification)
                .catch(() => { });
        }
    };

    handleRotate = () => {
        if (this.selectedDocument) {
            this.selectedDocument.rotate(90)
                .then(() => this.selectedDocument.fetch())
                .then(showSaveNotification)
                .catch(() => { });
        }
    };

    handleReset = () => {
        if (this.selectedDocument) {
            this.selectedDocument.reset()
                .then(() => this.selectedDocument.fetch())
                .then(showSaveNotification)
                .catch(() => { });
        }
    };

    requestScan = () => {
        const { declaration } = this.props;
        api.post(`declaration/${declaration.id}/request_new_scan/`)
            .then(() => {
                showNotification({
                    dismissAfter: 4000,
                    message: t('form.notifications.requestScanSuccess'),
                });
            })
    };

    picIndex = (doc) => {
        const { declaration } = this.props

        if (doc) {
            const index = declaration.documentsToDisplay.findIndex(item => item.id === doc.id)
            return index + 1
        }
    }

    color = () => {
        const { declaration } = this.props;
        let status = '#daeaf3'
        if (declaration.documentsToDisplay.length !== 0) {
            declaration.documentsToDisplay.find(document => {
                if (document.status === 'approved by customer service') {
                    status = '#b3ded8'
                } else if (document.status === 'rejected') {
                    status ='#fbdba7'
                }

            })

        }
        return status
    }

    renderPicChangeMenu = () => {
        const { declaration } = this.props;
        return (
            <ThertriaryWrapper>
                <Menu pagination size="mini">
                    <Menu.Item icon as="a" onClick={()=> this.handleSelect('left')}><Icon name="chevron left" /></Menu.Item>
                    {(declaration.documentsToDisplay.length !== 0 && this.selectedDocument) && <Menu.Item>{this.picIndex(this.selectedDocument)} / {declaration.documentsToDisplay.length}</Menu.Item>}
                    <Menu.Item icon as="a" onClick={()=> this.handleSelect('right')}><Icon name="chevron right" /></Menu.Item>
                </Menu>
            </ThertriaryWrapper>
        )
    }

    renderPicModal = () => {
        const { declaration } = this.props
        const wasScanRequested = declaration.documents?.wasScanRequestSent('declaration');

        return (
            <Modal
                open={this.isOpen}
                size="fullscreen"
                centered={false}
                trigger={(
                    <Form.Field>
                        <div style={{ display: 'flex' }}>
                            <SecondaryWrapper>
                                <DroppableButton data-test-upload-document-modal
                                    onDrop={this.uploadImage}
                                    accept='image/jpeg, image/png, application/pdf'
                                    title={t('activity.field.requiredDocuments.upload')}
                                />
                                <IconButton
                                    link
                                    name="download"
                                    href={`${process.env.REACT_APP_CY_FRONTEND_API_BASE_URL}declaration/${declaration.id}/all_documents/`}
                                    title={t('activity.field.requiredDocuments.downloadAll')}
                                />
                                <IconButton data-test-show-document-modal
                                    name="search"
                                    onClick={this.open}
                                    title={t('activity.field.requiredDocuments.searchModal')}
                                />
                                {!wasScanRequested && (
                                    <Button onClick={this.requestScan} disabled={declaration.rejectedOrApproved}>
                                        {t('planning.activityFinalize.requestScan')}
                                    </Button>
                                )}
                                <div>
                                    {this.selectedDocument && this.renderImage()}
                                </div>
                            </SecondaryWrapper>
                        </div>
                    </Form.Field>
                )}
            >
                <Modal.Header>
                    <Wrapper>
                        {t('declaration.declarationDocumentEdit')}
                        <RightDivider />
                        {t('declaration.lastUpdated')}
                        {declaration.updatedAt && declaration.updatedAt.format(DATETIME_FORMAT)}
                    </Wrapper>
                </Modal.Header>
                <Modal.Content>
                        <React.Fragment>
                            <Table celled style={{ backgroundColor: this.color() }}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            {declaration.transactionDatetime ? declaration.transactionDatetime.format(DATE_SHORT_FORMAT) : '-' }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {declaration.paymentMethod ? declaration.paymentMethod : '-' }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {this.declarationStatus(declaration)}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {declaration.fuelCost.id ? declaration.fuelCost.truck.licensePlate : declaration.roadCost.truck.licensePlate} / {declaration.driver.name}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            <Grid columns={2}>
                                <Grid.Column>
                                    {this.selectedDocument && <TargetTextInput fluid
                                        label='Remark'
                                        target={this.selectedDocument}
                                        name='rejectReason'
                                    />}
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='2' >
                                                    {t('declaration.details')}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell collapsing>
                                                    {t('declaration.type')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.fuelCost.id ? declaration.fuelCost.costProduct.description : declaration.roadCost.costProduct.description}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {t('declaration.date')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.transactionDatetime && declaration.transactionDatetime.format(DATETIME_FORMAT)}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {t('declaration.location')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.fuelCost.id ? declaration.fuelCost.location.address : declaration.roadCost.location.address}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {t('declaration.approved')}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {declaration.approvedAt ? 'Yes' : 'No'}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <PrimaryWrapper>
                                            {this.renderImage()}
                                        </PrimaryWrapper>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </React.Fragment>
                    {/* ))} */}

                </Modal.Content>
                <Modal.Actions>
                    <CancelButton data-test-cancel onClick={this.close} />
                    <RightDivider />
                    <React.Fragment>
                        <ItemButton primary
                            icon="sync"
                            disabled={this.isCropping}
                            onClick={() => this.handleReset()}
                        />
                        <ItemButton primary
                            icon="undo"
                            disabled={this.isCropping}
                            onClick={() => this.handleRotate()}
                        />
                        <ItemButton primary
                            icon="circle outline"
                            disabled={this.isCropping}
                            onClick={() => this.handleBrighten(1.1)}
                        />
                        <ItemButton primary
                            icon="circle"
                            disabled={this.isCropping}
                            onClick={() => this.handleBrighten(0.9)}
                        />
                        <ItemButton primary
                            icon="chess board"
                            disabled={this.isCropping}
                            onClick={() => this.handleSharpen(0.5)}
                        />
                        <ItemButton primary
                            icon="crop"
                            onClick={() => this.handleCrop()}
                        />
                    </React.Fragment>
                    <SaveButton data-test-reject-button color='red'
                        onClick={()=>{
                            if (this.selectedDocument) {
                                this.selectedDocument.rejectByEmployee();
                                this.close();
                            }
                        }}
                        content={t('declaration.reject')}
                        disabled={declaration.rejectedOrApproved}
                    />
                    <SaveButton data-test-approve-button primary
                        onClick={()=>{
                            if (this.selectedDocument) {
                                this.selectedDocument.approve();
                                this.close();
                            }
                        }}
                        content={t('declaration.approve')}
                        disabled={declaration.rejectedOrApproved}
                    />
                </Modal.Actions>
            </Modal>
        )
    }

    renderType = () => {
        const { groupedDeclarationStore } = this.props;
        let types = [];

        if (groupedDeclarationStore) {
            types = groupedDeclarationStore.map((declaration) => {
                const isSelectedDeclaration = () => {
                    if (this.props.declaration.id === declaration.id) {
                        return '#f5f5f5'
                    }
                    return 'white'
                }
                if (declaration.costProduct.id) {
                    return (<Table.Row style={{ backgroundColor: isSelectedDeclaration() }}><Table.Cell verticalAlign='top'>{declaration.costProduct.description}</Table.Cell><Table.Cell verticalAlign='top'><div data-test-amount={declaration.quantity}>{formatMoney(declaration.quantity)}{this.renderExchandeDiffPopup(declaration)}</div></Table.Cell></Table.Row>)
                } else if (declaration.roadCost.id) {
                    return (<Table.Row><Table.Cell verticalAlign='top'>{declaration.roadCost.costProduct.description}</Table.Cell><Table.Cell verticalAlign='top'><div data-test-amount={declaration.quantity}>{formatMoney(declaration.quantity)}{this.renderExchandeDiffPopup(declaration)}</div></Table.Cell></Table.Row>)
                } else if (declaration.fuelCost.id) {
                    return (<Table.Row><Table.Cell verticalAlign='top'>{declaration.fuelCost.costProduct.description}</Table.Cell><Table.Cell verticalAlign='top'><div data-test-amount={declaration.quantity}>{formatMoney(declaration.quantity)}{this.renderExchandeDiffPopup(declaration)}</div></Table.Cell></Table.Row>)
                }
            })
        }

        let type = null;

        if (this.props.declaration.costProduct.id) {
            type = (<Table.Row>
                        <Table.Cell verticalAlign='top'>{this.props.declaration.costProduct.description}</Table.Cell>
                        <Table.Cell verticalAlign='top'><div>{formatMoney(this.props.declaration.quantity)}</div></Table.Cell>
                        {this.renderExchandeDiffPopup(this.props.declaration)}
                    </Table.Row>)
        } else if (this.props.declaration.roadCost.id) {
            type = (<Table.Row>
                        <Table.Cell verticalAlign='top'>{this.props.declaration.roadCost.costProduct.description}</Table.Cell>
                        <Table.Cell verticalAlign='top'><div>{formatMoney(this.props.declaration.quantity)}</div></Table.Cell>
                        {this.renderExchandeDiffPopup(this.props.declaration)}
                    </Table.Row>)
        } else if (this.props.declaration.fuelCost.id) {
            type = (<Table.Row>
                        <Table.Cell verticalAlign='top'>{this.props.declaration.fuelCost.costProduct.description}</Table.Cell>
                        <Table.Cell verticalAlign='top'><div>{formatMoney(this.props.declaration.quantity)}</div></Table.Cell>
                        {this.renderExchandeDiffPopup(this.props.declaration)}
                    </Table.Row>)
        }

        if (types.length !== 0) {
            return types;
        }

        return type
    }

    @observable test = null

    render() {
        const { declaration } = this.props

        return (
            <React.Fragment>
                <Form.Group widths="equal">
                    <TargetMoneyInput fluid
                        label='Original Transaction amount'
                        target={declaration}
                        name={'totalQuantity'}
                        disabled={declaration.creator === 'simpled'}
                    />
                    <TargetTextInput fluid
                        label='Simpledcard number'
                        target={declaration.simpledTransaction}
                        name={'publicTokenString'}
                    />
                    <TargetTextInput fluid
                        label='Payment reference'
                        target={declaration.simpledTransaction}
                        name={'description'}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field width={15} style={{ paddingRight: 0 }}>
                        <Table textAlign='center' style={{ borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        {t('declaration.type')}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {t('declaration.amount')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderType()}
                            </Table.Body>
                        </Table>
                    </Form.Field>
                    <Form.Field width={15} style={{ paddingLeft: 0 }}>
                        <Table textAlign='center' style={{ borderLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        {t('declaration.files')}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Cell style={{ backgroundColor: '#f5f5f5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div >
                                        {this.renderPicModal()}
                                    </div>
                                </Table.Cell>
                            </Table.Body>
                        </Table>
                    </Form.Field>
                    <Form.Field width={6}>
                        <TargetTextArea fluid style={{ minHeight: 88.5, backgroundColor:'#ffffff', color:'grey' }}
                            target={declaration}
                            name="remark"
                            label='Driver Remark'
                            readOnly
                        />
                        <TargetTextArea fluid style={{ minHeight: 88.5 }}
                            target={declaration}
                            name="officeRemark"
                            label='Office Remark'
                        />
                        {declaration._history &&
                        <Radio toggle data-test-toggle-history-button
                            label={t('wage.toggleSystemHistory')}
                            onChange={() => this.toggleSystemHistory()}
                        />}
                    </Form.Field>
                </Form.Group>
                {this.showSystemHistory && this.renderHistory()}
            </React.Fragment>
        )
    }
}
