import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Body, ContentContainer, Content, Toolbar } from 're-cy-cle';
import { Form, Header, Grid, Button, Loader } from 'semantic-ui-react';
import { Location } from 'store/Location';
import { SaveButton  } from '@code-yellow/spider';
import { FormTextInput  } from '@code-yellow/spider';
import { RightDivider  } from '@code-yellow/spider';
import LocationEditFreeForm from 'container/LocationEditFreeForm';
import ActivityTruckMap from 'container/PlanningCustomer/ActivityDetails/TruckMap';
import { MapContainer } from 'component/Location/Map';
import { RestrictedZone, RestrictedZoneStore } from 'store/RestrictedZone';


const POINT_A = 'A'
const POINT_B = 'B'
const POINT_C = 'C'
const POINT_D = 'D'
export const POINTS = [POINT_A, POINT_B, POINT_C, POINT_D]

const pointArrayNumber = {
    'A' : 0,
    'B' : 1,
    'C' : 2,
    'D' : 3,
}

@observer
export default class RestrictedZoneEdit extends Component {
    static propTypes = {
        restrictedZone: PropTypes.instanceOf(RestrictedZone).isRequired,
        onBack: PropTypes.func.isRequired,
    };

    @observable fakeLocationPointA = new Location();
    @observable fakeLocationPointB = new Location();
    @observable fakeLocationPointC = new Location();
    @observable fakeLocationPointD = new Location();
    @observable restrictedZoneMarkers = []
    @observable restrictedZonePolygon = []
    @observable activePoint = POINT_A;
    @observable isLoading = false;
    @observable restrictedZoneStore = new RestrictedZoneStore();

    componentDidMount() {
        const { restrictedZone } = this.props;
        this.restrictedZoneStore.fetch();
        if (restrictedZone.id) {
            this.isLoading = true
            const promises = []
            POINTS.map(p => {
                const lat = restrictedZone[`point${p}`].lat
                const lng = restrictedZone[`point${p}`].lng
                const location = new Location({ address: `${lat}, ${lng}`});
                this[`fakeLocationPoint${p}`] = location
                promises.push(() => this[`fakeLocationPoint${p}`].geocode())
            })
            Promise.all(promises.map(fn => fn())).then(() => POINTS.map(p => {
                this.restrictedZoneMarkers.push({ type: `point${p}`, location: this[`fakeLocationPoint${p}`]})
                this.restrictedZonePolygon.push([this[`fakeLocationPoint${p}`].point.lat, this[`fakeLocationPoint${p}`].point.lng])
                this.isLoading = false
            }));
        }
    }

    save = () => {
        const { restrictedZone, onBack } = this.props;

        restrictedZone
            .save({ onlyChanges: true })
            .then(onBack);
    };

    changeActivePoint(point) {
        this.activePoint = point
    }

    changeToNextIndex() {
        const currentPointIndex = pointArrayNumber[this.activePoint];
        const points = Object.keys(pointArrayNumber);
        const nextIndex = (currentPointIndex + 1) % points.length;
        const nextPoint = points[nextIndex];
        this.activePoint = nextPoint
      }

    handleAddMarkerAndDrawPolygon(point) {
        if (this.activePoint === point) {
            const index = pointArrayNumber[point]

            if (this.restrictedZoneMarkers[index]) {
                this.restrictedZoneMarkers[index] = { type: `point${point}`, location: this[`fakeLocationPoint${point}`]}
            } else {
                this.restrictedZoneMarkers.push({ type: `point${point}`, location: this[`fakeLocationPoint${point}`]})
            }

            if (this.restrictedZonePolygon[index]) {
                this.restrictedZonePolygon[index] = [this[`fakeLocationPoint${point}`].point.lat, this[`fakeLocationPoint${point}`].point.lng]
            } else {
                this.restrictedZonePolygon.push([this[`fakeLocationPoint${point}`].point.lat, this[`fakeLocationPoint${point}`].point.lng])
            }
        }
    }

    renderPoint(point) {
        const { restrictedZone } = this.props;
        return (
            <div style={this.activePoint === point ? {} : {opacity : '0.5'}}>
            <LocationEditFreeForm
                onChange={(someLocation) => {
                    this.activePoint = point
                    this[`fakeLocationPoint${point}`].parse(someLocation.toJS());
                    this.handleAddMarkerAndDrawPolygon(point)
                    restrictedZone.setInput(`point${point}`, {
                        lat: this[`fakeLocationPoint${point}`].point.lat,
                        lng: this[`fakeLocationPoint${point}`].point.lng,
                    });
                }}
                disabled={this.isLoading}
                label={<Button disabled={this.isLoading} compact active={this.activePoint === point} onClick={() => this.changeActivePoint(point)}>{t(`restrictedZone.field.point${point}.label`)}</Button>}
                location={this[`fakeLocationPoint${point}`]}
                name="location"
                placeholder={t('restrictedZone.edit.locationPlaceholder')}
                rows={1}
            />
            </div>)
    }

    render() {
        const { restrictedZone } = this.props;

        return (
            <Body>
                <ContentContainer>
                    <Content>
                        <Grid columns={2}>
                            <Grid.Column width={8}>
                                <Header>
                                    {!restrictedZone.isNew ? t('restrictedZone.edit.label' ) : t('restrictedZone.edit.labelNew')}
                                </Header>
                                <Form>
                                    {(this.isLoading || restrictedZone.isLoading) && <Loader active/>}
                                    <FormTextInput
                                        required
                                        model={restrictedZone}
                                        name="name"
                                    />
                                    {POINTS.map((point) => this.renderPoint(point))}

                                {(this.isLoading || restrictedZone.isLoading) && <Loader active/>}
                                    <div style={{textAlign: 'right', position: 'relative', top: '5px'}}> {restrictedZone.bannedReason.length}/30</div>
                                    <div style={{marginTop: '-1rem'}}>
                                    <FormTextInput
                                        label='Banned Reason'
                                        fluid
                                        maxLength={30}
                                        required
                                        model={restrictedZone}
                                        name="bannedReason"
                                    />
                                    </div>
                                {(this.isLoading || restrictedZone.isLoading) && <Loader active/>}
                                    <div style={{marginTop: '1rem'}}>
                                    <FormTextInput
                                        label='Banned Remark'
                                        model={restrictedZone}
                                        name="bannedRemark"
                                    />
                                    </div>
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <MapContainer>
                                    <ActivityTruckMap
                                        activityMarkers={this.restrictedZoneMarkers ? this.restrictedZoneMarkers : undefined}
                                        restrictedZonePolygon={this.restrictedZonePolygon}
                                        restrictedZoneStore={this.restrictedZoneStore}
                                        onClick={({ lat, lng }) => {
                                            this.isLoading = true
                                            const fakeLocation = new Location({ address: `${lat}, ${lng}`});

                                            fakeLocation.geocode().then(() => {
                                                this[`fakeLocationPoint${this.activePoint}`] = fakeLocation;
                                                restrictedZone.setInput(`point${this.activePoint}`, {
                                                    lat: fakeLocation.point.lat,
                                                    lng: fakeLocation.point.lng,
                                                });
                                                this.handleAddMarkerAndDrawPolygon(this.activePoint)
                                                this.isLoading = false
                                                this.changeToNextIndex()
                                            });
                                        }}
                                    />
                                </MapContainer>
                            </Grid.Column>
                        </Grid>
                    </Content>
                </ContentContainer>
                <Toolbar>
                    <RightDivider />
                    <SaveButton data-test-save-button primary compact loading={this.isLoading} onClick={this.save} />
                </Toolbar>
            </Body>
        );
    }
}
