import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { BEPTicket } from './BEPTicket';
import { Driver } from './Driver';
import { DriverBonus } from './DriverBonus';
import { PayrollCompany } from './PayrollCompany';
import { User } from './User';
import moment from 'moment-timezone';
import { showSaveNotification } from '@code-yellow/spider';
import { get } from 'lodash';
import { storeWithHistory, modelWithHistory } from './History';


export class Bonus extends Model {
    static backendResourceName = 'bonus';

    @observable id = null;
    @observable dateObtained = null;
    @observable bonusRate = null;
    @observable remark = '';
    @observable isBonus = true;
    @observable updatedAt = null;
    @observable finalizedAt = null;
    @observable type = null;
    @observable deleted = false;

    casts() {
        return {
            dateObtained: Casts.date,
            updatedAt: Casts.datetime,
            finalizedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            driver: Driver,
            driverBonus: DriverBonus,
            finalizedBy: User,
            bepTicket: BEPTicket,
            payrollCompany: PayrollCompany,
            createdBy: User,
        };
    }

    makeDeductionAlwaysNegative() {
        if (this.bonusRate > 0 && this.type === 'deduction'){
            return this.bonusRate = this.bonusRate * -1
        }
    }

    makeBonusAlwayspositive() {
        if (this.bonusRate < 0 && this.type === 'bonus'){
            return this.bonusRate = this.bonusRate * -1
        }
    }

    finalize() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}finalize/`)
            .then(() => {
                this.setInput('finalizedAt', moment());
            }).then(showSaveNotification)
        );
    }

    unfinalize() {
        return this.wrapPendingRequestCount(
            this.api.post(`${this.url}unfinalize/`)
                .then(() => {
                    this.setInput('finalizedAt', null);
                }).then(showSaveNotification)
                .catch(e => {
                    if (get(e, 'response.data.errors')) {
                        this.parseValidationErrors(e.response.data.errors);
                    }
                    throw e;
                }
            )
        );
    }
}

export class BonusStore extends Store {
    Model = Bonus;
    static backendResourceName = 'bonus';

    getTotalAmount() {
        return this.models.reduce((result, bonus) => result + bonus.bonusRate, 0);
    }
}

export class HistoryBonusStore extends storeWithHistory(BonusStore){};
export class HistoryBonus extends modelWithHistory(Bonus){};
